import { createContext, ReactNode, useContext, useState } from "react";
import { useLocation } from "react-router-dom";

interface Props {
  children?: ReactNode;
}
export const ThemeContext = createContext({
  color: "",
  handleColor: (value: string) => {},
});

export const ThemeProvider = ({ children }: Props) => {
  const location = useLocation();

  const [color, setColor] = useState<string>(
    location.pathname === "/conversations"
      ? "green"
      : location.pathname === "/campagnes"
      ? "purple"
      : "gray"
  );

  const handleColor = (value: string) => {
    setColor(value);
  };

  const value = {
    color,
    handleColor,
  };
  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};
export const useThemeContext = () => useContext(ThemeContext);
