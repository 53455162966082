import React, { FormEvent, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { PhoneInput } from "react-international-phone";
import 'react-international-phone/style.css';
import { storeContacts } from "../../../../services/contacts-service";
import { toast } from "react-toastify";
import { Spinner } from "../../../../components";
import { toastConfig } from "../../../../utils/utils";

interface AddContactProps {
  open: boolean;
  color?: string;
  setOpen: () => void;
  setReload: (r: boolean) => void;
}

export default function AddContactDialog({ open, setOpen, setReload, color }: AddContactProps) {
  const [phone, setPhone] = React.useState("")
  const [name, setName] = React.useState("")
  const [email, setEmail] = React.useState("")
  const [city, setCity] = React.useState("")
  const [address, setAddress] = React.useState("")
  const [loading, setLoading] = React.useState(false)

  async function handleSubmit(e: FormEvent) {
    e.preventDefault()
    setLoading(true)
    const body = {
      phone: phone.replace(/\s/g, ""),
      email: email,
      name: name,
      city: city,
      address: address,
    };
    await storeContacts(body).then((res: boolean) => {
      if (res) {
        toast.success("Contact Cree avec success",{
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          // transition: Bounce,
          });
        setReload(true);
        setOpen()
      } else {
        toast.error("Erreur Lors de la creation ",toastConfig)
      }
    }).catch((e) => {
      toast.error(e,toastConfig);
    }).finally(() => setLoading(false))
  }
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500  focus:outline-none focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={setOpen}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <form action="" method="post" onSubmit={async (e) => handleSubmit(e)}>
                  <div className="sm:flex sm:items-start w-full">
                    <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900 px-4"
                      >
                        Créer un contact
                      </Dialog.Title>
                      <hr className="my-4" />
                      <div className="flex flex-col gap-4 w-full px-4">
                        <div className="w-full gap-x-4">
                          <div>
                            <label
                              htmlFor="email"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Nom Complet <code className="text-red-500">*</code>
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                name="name"
                                className="block w-full px-4 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-0 focus:outline-purple-600 focus:ring-purple-600 sm:text-sm sm:leading-6"
                                placeholder="Nom du contact"
                                required
                                onInput={(e) => {
                                  setName(e.currentTarget.value) 
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="grid grid-cols-2 gap-1 justify-between">
                          <div className="">
                            <label
                              htmlFor="email"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Numéro de téléphone <code className="text-red-500">*</code>
                            </label>
                            <div className="mt-2">
                            <PhoneInput
                              className="w-full"
                              defaultCountry="cm"
                              placeholder="Enter phone number"
                              // value={field.value}
                              style={{ width:"100%" }}
                              onChange={setPhone}
                              required
                            />
                            </div>
                          </div>
                          
                          <div>
                            <label
                              htmlFor="email"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Email
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                name="email"
                                id="email"
                                className="block w-full px-4 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-0 focus:outline-purple-600 focus:ring-purple-600 sm:text-sm sm:leading-6"
                                placeholder="Email du contact"
                                onInput={(e) => {
                                  setEmail(e.currentTarget.value) 
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="grid grid-cols-2 gap-1 justify-between">
                          <div className="">
                            <label
                              htmlFor="email"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Ville
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                name="city"
                                className="block w-full px-4 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-0 focus:outline-purple-600 focus:ring-purple-600 sm:text-sm sm:leading-6"
                                placeholder="Ville"
                                onInput={(e) => {
                                setCity(e.currentTarget.value) 
                                }}
                              />
                            </div>
                          </div>
                          
                          <div>
                            <label
                              htmlFor="email"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Addresse
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                name="address"
                                className="block w-full px-4 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-0 focus:outline-purple-600 focus:ring-purple-600 sm:text-sm sm:leading-6"
                                placeholder="Addresse"
                                onInput={(e) => {
                                  setAddress(e.currentTarget.value) 
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                  <hr className="my-8" />
                  <div className={"mt-5 sm:mt-4 sm:flex sm:flex-row-reverse justify-between px-4 "}>
                    <button
                      type="submit"
                      className={"inline-flex w-full justify-center rounded-md bg-"+( typeof color == "undefined"?"purple":color)+"-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-"+( typeof color == "undefined"?"purple":color)+"-500 sm:ml-3 sm:w-auto " + (loading? "hidden":"")}
                      disabled={loading}
                    >
                      Créer un contact
                    </button>
                    <button
                      type="button"
                      className={"mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto " + (loading? "hidden":"")}
                      onClick={setOpen}
                      disabled={loading}
                    >
                      Annuler
                    </button>
                  </div>
                  <div className={"mt-5 sm:mt-4 sm:flex sm:flex-row-reverse justify-end px-4 "}>
                    <span className={!loading? "hidden":""}>
                      <Spinner size="sm" />
                    </span>
                  </div>
                  
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
