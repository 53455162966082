import classNames from "classnames";

type UserSize = "lg" | "md" | "sm";
type UserType = "primary" | "danger" | "green" | "warning";

interface UserAvatarProps {
  type: UserType;
  size: UserSize;
  name: string;
  isOnline: boolean;
}

const userType = {
  primary: ["bg-blue-200", "text-blue-500"],
  danger: ["bg-red-200", "text-red-500"],
  green: ["bg-green-200", "text-green-500"],
  warning: ["bg-yellow-200", "text-yellow-500"],
};

const userSize = {
  lg: ["h-10 w-10"],
  md: ["h-6 w-6"],
  sm: ["h-4 w-4"],
};

export default function UserAvatar({
  type,
  name,
  size,
  isOnline,
}: UserAvatarProps) {
  return (
    <div
      className={classNames(
        userType[type],
        userSize[size],
        "relative z-30 flex items-center justify-center rounded-full ring-2 ring-white"
      )}
    >
      <span
        className={classNames(
          { "text-sm": size === "lg" },
          { "text-xs": size === "md" },
          { "text-[10px]": size === "sm" },
          "uppercase"
        )}
      >
        {name.slice(0, 2)}
      </span>
      {isOnline ? (
        <span className="absolute bottom-0 z-40 right-0 block h-2.5 w-2.5 rounded-full bg-green-400 ring-2 ring-white" />
      ) : (
        <></>
      )}
    </div>
  );
}
