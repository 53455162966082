import classNames from "classnames";
import React, { Fragment } from "react";
import AddContactDialog from "./contact/add-contact-dialog";
import DataTable, { TableColumn } from 'react-data-table-component';
import {
  EllipsisVerticalIcon,
  EyeIcon,
  IdentificationIcon,
  PencilIcon,
  PencilSquareIcon,
  ArrowsUpDownIcon,
  TrashIcon,
  BoltIcon,
} from "@heroicons/react/24/outline";
import { fetchContacts, synchronizeContact } from "../../../services/contacts-service";
import { Contact as ContactModel } from "../../../models/contact-model";
import PlaceholderLoading from "react-placeholder-loading";
import ImportContactDialog from "./contact/import-contacts-dialog";
import { Menu, Transition } from "@headlessui/react";
import EditContactDialog from "./contact/edit-contact-dialog";
import DeleteContactDialog from "./contact/delete-contacts-dialog";
import { Spinner } from "../../../components";
import { toast, ToastContainer } from "react-toastify";
import { toastConfig } from "../../../utils/utils";

interface MenuProps {
  contact: ContactModel;
  setSelectedContact: (sc: ContactModel) => void;
  setUpdateOpen: (uo: boolean) => void;
  setDeleteOpen: (uo: boolean) => void;
}
export default function Contact() {
  const [statusTab, setStatusTab] = React.useState<number>(0);
  const [open, setOpen] = React.useState(false);
  const [importOpen, setImportOpen] = React.useState(false);
  const [updateOpen, setUpdateOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [reload, setReload] = React.useState(false);
  const [contactsFetched, setContactsFetched] = React.useState(false);
  const [contacts, setContacts] = React.useState<ContactModel[]>([]);
  const [selectedContact, setSelectedContact] = React.useState<ContactModel>();

  const secondTabs = [
    {
      name: "Tous ("+ contacts.length + ")",
      href: "#",
      current: true,
      state: 0,
    },
    // {
    //   name: "Bloqué",
    //   href: "#",
    //   current: false,
    //   state: 1,
    // },
  ];
  
  const columns: TableColumn<ContactModel>[] = [
    {
      name: 'NUMERO',
      selector: (row: ContactModel) => row.phone,
    },
    {
      name: 'NOM',
      selector: (row: ContactModel) => row.name,
    },
    {
      name: 'VILLE',
      selector: (row: ContactModel) => row.city,
    },
    {
      name: 'ADDRESSE',
      selector: (row: ContactModel) => row.address,
    },
    {
      name: 'EMAIL',
      selector: (row: ContactModel) => row.email,
    },
    
    {
      name: 'ACTIONS',
      selector: (row: ContactModel) => row.id,
      cell: (row: ContactModel) => {
        return (
          <Menu
            as="div"
            className="relative inline-block text-left"
          >
            <div>
              <Menu.Button className="inline-flex w-full justify-center  px-4 py-2 text-sm font-medium text-purple-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75">
                <EllipsisVerticalIcon className="h-6 w-6" />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-50 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                <div className="px-1 py-1 ">
                  <div className="px-1 py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${
                            active
                              ? "bg-purple-500 text-white"
                              : "text-gray-900"
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                          onClick={() => {
                            setSelectedContact(row)
                            setUpdateOpen(true)
                          }}
                        >
                          {active ? (
                            <PencilIcon
                              className="mr-2 h-5 w-5"
                              aria-hidden="true"
                            />
                          ) : (
                            <PencilIcon
                              className="mr-2 h-5 w-5"
                              aria-hidden="true"
                            />
                          )}
                          Modifier
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={`${
                          active
                            ? "bg-purple-500 text-white"
                            : "text-gray-900"
                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        onClick={() => {
                            setSelectedContact(row)
                            setDeleteOpen(true)
                        }}
                      >
                        {active ? (
                          <TrashIcon
                            className="mr-2 h-5 w-5"
                            aria-hidden="true"
                          />
                        ) : (
                          <TrashIcon
                            className="mr-2 h-5 w-5"
                            aria-hidden="true"
                          />
                        )}
                        Supprimer
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        );
      }
    },
    
    
  ];
  

  async function getContacts() {
    setLoading(true);
    await fetchContacts().then((cs: ContactModel[]) => {
      setContacts(cs);
      setContactsFetched(true);
    }).catch((err) => {
      toast.error(err, toastConfig)
    }).finally(() => {
      setLoading(false);
    })
  }

async function syncContacts() {
    setLoading(true);
    await synchronizeContact().then((res: boolean) => {
      if (res) {
        getContacts();
      }
    }).catch((err) => {
      toast.error(err, toastConfig)
    }).finally(() => {
      setLoading(false);
    })
  }

  React.useEffect(() => {
    if (!contactsFetched || reload) {
      getContacts();
      setReload(false)
    }
  }, [reload])

  return (
    <>
      <div className=" max-w-6xl mx-auto">
        <div className="flex flex-col divide-y">
          <div className=" px-4 py-4 gap-x-4 ">
            <div>
              <div className="flex items-center gap-2">
                <IdentificationIcon className="h-6 w-6" />
                <h1>Contacts</h1>
              </div>
              <div className="mt-8">
                <div className="flex flex-col divide-y border">
                  <div className="flex items-center justify-between px-5 py-2">
                    <input
                      type="text"
                      className="border rounded-md h-10 px-2 w-96 font-light text-gray-600 placeholder:text-sm placeholder:text-gray-400 placeholder:font-light focus:outline-purple-600"
                      placeholder="Rechercher des contacts"
                    />
                    <div className="flex items-center gap-x-2">
                      {/* <button title="Synchronizer" className="border hover:bg-white/50 px-4 py-2 rounded-sm text-sm" data-tooltip-target="tooltip-default" onClick={() => {syncContacts()}}>
                        <BoltIcon width={20} height={20} className="" />
                      </button> */}
                      {/* <div id="tooltip-default" role="tooltip" className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                          Tooltip content
                          <div className="tooltip-arrow" data-popper-arrow></div>
                      </div> */}
                      <button className="border hover:bg-white/50 px-4 py-2 rounded-sm text-sm" onClick={() => setImportOpen(true)}>
                        Importer contact
                      </button>
                      <button
                        onClick={() => setOpen(true)}
                        className="text-white bg-purple-600 hover:bg-purple-500 px-4 py-2 rounded-lg text-sm"
                      >
                        Ajouter le contact
                      </button>
                    </div>
                  </div>
                  <div>
                    <div className="flex justify-start">
                      <nav
                        className="-mb-px flex space-x-8 px-5"
                        aria-label="Tabs"
                      >
                        {secondTabs.map((tab) => (
                          <div
                            key={tab.name}
                            onClick={() => setStatusTab(tab.state)}
                            className={classNames(
                              statusTab === tab.state
                                ? "border-purple-500 text-purple-600"
                                : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                              "group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium cursor-pointer"
                            )}
                            aria-current={tab.current ? "page" : undefined}
                          >
                            <span>{tab.name}</span>
                          </div>
                        ))}
                      </nav>
                    </div>
                    {statusTab === 0 ? (
                      <>
                        <div className="px-4 sm:px-6 lg:px-8">
                          <div className="mt-8 flow-root">
                            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                              <DataTable
                                  columns={columns}
                                  data={contacts}
                                  pagination
                                  progressPending={loading}
                                  progressComponent={
                                    <div className="my-2">
                                      <Spinner size="sm" />
                                    </div>
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="flex flex-col justify-center items-center py-8 gap-y-6">
                        <img
                          src="/assets/images/campagnes/not_found.png"
                          alt=""
                          className="h-40 w-40"
                        />
                        <h1 className="text-2xl font-semibold text-purple-600">
                          Aucun contact trouvé
                        </h1>
                        <h5 className="text-sm font-normal text-gray-500">
                          Vous pouvez essayer avec une autre recherche ou
                          modifier les filtres de recherche
                        </h5>
                        <div className="flex items-center gap-x-2">
                          <button className="text-white bg-purple-600 hover:bg-purple-500 px-4 py-2 rounded-lg text-sm">
                            Réinitialiser la recherche
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddContactDialog open={open} setOpen={() => setOpen(!open)} setReload={(r: boolean) => setReload(r)} />
      <ImportContactDialog open={importOpen} setOpen={() => setImportOpen(!importOpen)} setReload={(r: boolean) => setReload(r)} />
      <EditContactDialog open={updateOpen} setOpen={() => setUpdateOpen(!updateOpen)} setReload={(r: boolean) => setReload(r)} contact={selectedContact!} />
      <DeleteContactDialog open={deleteOpen} setOpen={() => setDeleteOpen(!deleteOpen)} setReload={(r: boolean) => setReload(r)} contact={selectedContact!} />
      <ToastContainer
       position="top-right"
       autoClose={5000}
       
       hideProgressBar={false} // Other desired configurations
       newestOnTop={false}
       closeOnClick
       rtl={false}
       pauseOnFocusLoss
       draggable
       pauseOnHover
       theme="light"
     />
    </>
  );
}
