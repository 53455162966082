interface StepTwoProps {
  handleNext: () => void;
  setSimilarMessages: (sm: boolean) => void;
}
export default function StepTwo({ handleNext, setSimilarMessages }: StepTwoProps) {
  return (
    <div className="border">
      <div className="flex flex-col divide-y">
        <div className="grid grid-cols-2 px-4 py-8 gap-x-4 ">
          <div
            onClick={() => {
              setSimilarMessages(true)
              handleNext()
            }}
            className="flex flex-col justify-center items-center py-12 gap-y-4 border cursor-pointer"
          >
            <img
              src="/assets/images/campagnes/campagne-2.png"
              alt=""
              className="h-52 w-36"
            />
            <h1 className="text-lg text-center font-semibold text-gray-500">
              Envoyer le{" "}
              <span className="text-white bg-purple-600 p-2 py-1">
                même message
              </span>{" "}
              à tous les destinataires
            </h1>
            <h5 className="text-xs text-center font-normal text-gray-500 max-w-md">
              Envoyez le même message à plusieurs contacts en les important ou
              en les sélectionnant depuis le CRM
            </h5>
          </div>
          <div
            onClick={() => {
              setSimilarMessages(false)
              handleNext()
            }}
            className="flex flex-col justify-center items-center py-12 gap-y-4 border cursor-pointer"
          >
            <img
              src="/assets/images/campagnes/campagne-3.png"
              alt=""
              className="h-52 w-36"
            />
            <h1 className="text-lg text-center font-semibold text-gray-500">
              Envoyer un{" "}
              <span className="text-white bg-purple-600 p-2 py-1">
                message différent
              </span>{" "}
              à chaque destinataire
            </h1>
            <h5 className="text-xs text-center font-normal text-gray-500 max-w-md">
              Importez facilement plusieurs contacts et envoyez un message
              différent à chaque contact
            </h5>
          </div>
        </div>
        <div className="flex items-center justify-between px-5 py-2">
          <button className="border hover:bg-white/50 px-4 py-2 rounded-sm text-sm">
            Annuler
          </button>
          <button
            onClick={() => handleNext()}
            className="text-white bg-purple-600 hover:bg-purple-500 px-4 py-2 rounded-sm text-sm"
          >
            Continuer
          </button>
        </div>
      </div>
    </div>
  );
}
