import { DocumentIcon, DownloadIcon } from "./icons";

export default function Document() {
  return (
    <div className="flex items-center justify-between">
      <div className="flex gap-4 items-center">
        <DocumentIcon />
        <div className="flex flex-col items-start">
          <span className="font-semibold text-xs ">invoice.docx</span>
          <span className="font-light text-[10px]">DOC 555kb</span>
        </div>
      </div>
      <DownloadIcon className="h-6 w-6 text-green-600" />
    </div>
  );
}
