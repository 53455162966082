import classNames from "classnames";
import {
  CAMPAGNE_CONTACT,
  CAMPAGNE_DASHBOARD,
  CAMPAGNE_FOLLOW,
  CAMPAGNE_MEDIA,
  CAMPAGNE_NEW,
  CAMPAGNE_NEW_TEMPLATE,
  CAMPAGNE_TEMPLATE,
} from "../../core/constants/layout";
import { useLayoutContext } from "../../core/context/layout-context";
import ChartCampagne from "./components/chart-campagne";
import Contact from "./components/contact";
import FollowCampagne from "./components/follow-campagne";
import NewCampagne from "./components/new-campagne";
import TemplateCampagne from "./components/temlate-campagne";
import CreateTemplate from "./components/template-campagne/create-template";
import AddCampainCatalog from "./components/new-promotional-campagne/add-campain-catalog";
import { useNavigate } from "react-router-dom";

export default function CampagnesPage() {
  const { selectChildren } = useLayoutContext();
  const navigate = useNavigate();

  function cancelCreation() {
    navigate(`${CAMPAGNE_NEW}`);
    window.location.reload();
  }

  const ChildrenCampagnes = () => {
    switch (selectChildren) {
      case CAMPAGNE_FOLLOW:
        return <FollowCampagne />;
      case CAMPAGNE_NEW:
        return <NewCampagne />;
      case CAMPAGNE_CONTACT:
        return <Contact />;
      case CAMPAGNE_TEMPLATE:
        return <TemplateCampagne />;
      case CAMPAGNE_NEW_TEMPLATE:
        return <CreateTemplate />;
      case CAMPAGNE_MEDIA:
        return <AddCampainCatalog cancel={cancelCreation}  />;
      
      default:
        return <ChartCampagne />;
    }
  };
  return (
    <div
      className={classNames(
        { "pt-14": selectChildren === CAMPAGNE_DASHBOARD },
        { "pt-24 pb-8": selectChildren !== CAMPAGNE_DASHBOARD },
        "xl:px-4 pb-6 h-screen bg-white overflow-y-auto"
      )}
    >
      {ChildrenCampagnes()}
    </div>
  );
}
