import React, { FormEvent, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { PhoneInput } from "react-international-phone";
import 'react-international-phone/style.css';
import { toast } from "react-toastify";
import { Spinner } from "../../../../components";
import { displayFrequencyType, getDaysBetweenDates, getFormattedDate, sortWeekDays, toastConfig } from "../../../../utils/utils";
import { deletePromotionalCampain } from "../../../../services/campagnes-service";
import { PromotionalCampagne } from "../../../../models/campagne-model";
import { Contact } from "../../../../models/contact-model";
import { Catalog } from "../../../../models/catalog-model";

interface DeletePromCampainProps {
  open: boolean;
  setOpen: () => void;
  setReload: (r: boolean) => void;
  campain: PromotionalCampagne;
}

export default function ViewPromotionalCampain({ open, setOpen, setReload, campain }: DeletePromCampainProps) {
  const [loading, setLoading] = React.useState(false)
  
  function displayFrequencyDate(campain: PromotionalCampagne) {
    switch (campain?.frequencyType) {
      case "ONE_TIME":
        if (typeof campain?.dispatchDate != "undefined") {
          return (
            <>
              Dans {getDaysBetweenDates(new Date().toDateString(), campain!.dispatchDate)} jours<br />
              <span className="text-xs font-light">
                {getFormattedDate(campain?.dispatchDate,true,false)} a {getFormattedDate(campain.dispatchTime,false,true)}
              </span>
            </>
          );
        }
        break;
      case "DAILY":
        return (
          <>
            Chaque Jour <br />
            <span className="text-xs font-light">
              a {getFormattedDate(campain.dispatchTime,false,true)}
            </span>
          </>
        );
        break;
      case "MONTHLY":
        return (
          <>
            Chaque Mois le {campain?.frequencyDate} <br />
            <span className="text-xs font-light">
              a {getFormattedDate(campain.dispatchTime,false,true)}
            </span>
          </>
        );
        break;
      case "YEARLY":
        return "Annuel";
        break;
      case "WEEKLY":
        if (typeof campain.frequencyDate != "undefined") {
          return (
            <>
              Chaque {sortWeekDays(campain?.frequencyDate)} <br />
              <span className="text-xs font-light">
                a {getFormattedDate(campain.dispatchTime,false,true)}
              </span>
            </>
          );
        }
        break;
      
      default:
        break;
    }
  }
  
  return (
    <Transition.Root show={open} as={Fragment} >
      <Dialog className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500  focus:outline-none focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={setOpen}
                  >
                    <span className="sr-only">Close</span>
                    <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                  <div className="sm:flex sm:items-start w-full">
                    <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900 px-4"
                      >
                        {campain?.name} <span 
                                className={"inline-flex items-center rounded-md bg-"+(campain?.status == 0?"yellow":"green")+"-50 px-2 py-1 text-xs font-medium text-"+(campain?.status == 0?"yellow":"green")+"-800 ring-1 ring-inset ring-"+(campain?.status == 0?"yellow":"green")+"-600/20"}>
                                {campain?.status == 0?"inactif":"actif"}
                                </span>
                      </Dialog.Title>
                      <hr className="my-4" />
                      <div className="flex flex-col gap-4 w-full px-4">
                        {/*  */}
                        <div className={"rounded-md bg-gray-50 p-4 shadow-md w-full"}>
                          <div className="grid grid-cols-2">
                            <div className="">
                              <b>Nom</b>: {campain?.name}
                            </div>
                            <div className="text-end">
                              <b>Cannal D'envoi</b>: {campain?.channel}
                            </div>
                          </div>
                          <hr className="my-5 py-3" />
                          <div className="grid grid-cols-2 my-1">
                            <div className="">
                              <b>Type de frequence</b>: {displayFrequencyType(campain?.frequencyType)}
                            </div>
                            <div className="text-end">
                              <b>Frequence/Date Prevue</b>: {displayFrequencyDate(campain)}
                            </div>
                          </div>
                          <hr className="my-5 py-3" />
                          <div className="grid grid-cols-2 my-1">
                            <div className="">
                              <b>Contacts</b>: 
                              <div className="my-2">
                                <ul>
                                  {campain?.contacts.map((contact: Contact) => {
                                    return (
                                      <li>{contact.phone} ({contact.name})</li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                            <div className="text-end">
                            <b>Medias</b>: 
                            <br />
                              <div className={"flex float-end"}>
                                  {campain?.catalog?.map((item: Catalog) => {
                                    return (
                                      // <div className="my-2 mx-auto content-center w-full">
                                      // </div>
                                        <img
                                          src={item.url}
                                          className="w-12 h-12 rounded text-end m-2"
                                          alt=""
                                        />
                                    );
                                  })}
                              </div>
                            </div>
                          </div>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="my-8" />
                  <div className={"mt-5 sm:mt-4 sm:flex sm:flex-row-reverse justify-between px-4 "}>
                    <button
                      type="button"
                      className={"mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto " + (loading? "hidden":"")}
                      onClick={setOpen}
                      disabled={loading}
                    >
                      Annuler
                    </button>
                  </div>
                  <div className={"mt-5 sm:mt-4 sm:flex sm:flex-row-reverse justify-end px-4 "}>
                    <span className={!loading? "hidden":""}>
                      <Spinner size="sm" />
                    </span>
                  </div>
                  
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
