export default function TextBoldIcon({
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 12.75H4.88C4.47 12.75 4.13 12.41 4.13 12V4.5C4.13 2.98 5.36 1.75 6.88 1.75H12C15.03 1.75 17.5 4.22 17.5 7.25C17.5 10.28 15.03 12.75 12 12.75ZM5.62 11.25H12C14.21 11.25 16 9.46 16 7.25C16 5.04 14.21 3.25 12 3.25H6.88C6.19 3.25 5.63 3.81 5.63 4.5V11.25H5.62Z"
        fill="currentColor"
      />
      <path
        d="M14.38 22.25H6.88C5.36 22.25 4.13 21.02 4.13 19.5V12C4.13 11.59 4.47 11.25 4.88 11.25H14.38C17.41 11.25 19.88 13.72 19.88 16.75C19.88 19.78 17.41 22.25 14.38 22.25ZM5.62 12.75V19.5C5.62 20.19 6.18 20.75 6.87 20.75H14.37C16.58 20.75 18.37 18.96 18.37 16.75C18.37 14.54 16.58 12.75 14.37 12.75H5.62Z"
        fill="currentColor"
      />
    </svg>
  );
}
