import { CheckIcon } from "@heroicons/react/24/solid";
import React from "react";

import StepTemplate from "./step-template";
import AddCampainCatalog from "./add-campain-catalog";
import NamePromotionalCampain from "./name-promo-campain";
import AddCampainDestinations from "./add-campain-destinations";
import ConfigureCampainFrequency from "./configure-campain-frequency";
import StepSixPromotional from "./step-six";
import StepOnePromotional from "./step-one";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { CAMPAGNE_FOLLOW, CAMPAGNE_NEW } from "../../../../core/constants/layout";
const steps = [
  { id: "01", name: "Sélectionnez le Cannal", status: 1 },
  { id: "02", name: "Nommez votre campagne", status: 2 },
  { id: "03", name: "Définir le public", status: 3 },
  { id: "04", name: "Ajouter Catalogue", status: 4 },
  { id: "05", name: "Configurer la campagne", status: 5 },
  { id: "06", name: "Confirmation", status: 6 },
];

export default function OnboardingPromotionalCampagne() {
  const [status, setStatus] = React.useState<number>(1);
  const [channel, setChannel] = React.useState<string>();
  const [name, setName] = React.useState<string>("");
  const [frequencyType, setFrequencyType] = React.useState<string>("");
  const [dispatchDate, setDispatchDate] = React.useState<string>("");
  const [frequencyDate, setFrequencyDate] = React.useState<string>("");
  const [dispatchTime, setDispatchTime] = React.useState<string>("");
  const [message, setMessage] = React.useState<string>("");
  const [contacts, setContacts] = React.useState<Array<number>>([]);
  const [catalog, setCatalog] = React.useState<Array<number>>([]);
  const [campainStatus, setCampainStatus] = React.useState<boolean>();

  const navigate = useNavigate();

  function cancelCreation() {
    navigate(`${CAMPAGNE_NEW}`);
    window.location.reload();
  }

  const onboarding = () => {
    switch (status) {
      case 2:
        return <NamePromotionalCampain handleNext={() => setStatus(3)} setName={(n:string) => setName(n)} name={name} cancel={() => cancelCreation()} />;
      case 3:
        return <AddCampainDestinations handleNext={() => setStatus(4)} setCampainContacts={(cs: Array<number>) => setContacts(cs)} cancel={() => cancelCreation()} />;
      case 4:
        return <AddCampainCatalog handleNext={() => setStatus(5)} setCampainCatalog={(cs: Array<number>) => setCatalog(cs)} cancel={() => cancelCreation()}/>;
      case 5:
        return <ConfigureCampainFrequency 
        handleNext={() => setStatus(6)}
        setCampainDispatchDate={(c:string) => setDispatchDate(c)}
        setCampainDispatchTime={(t:string) => setDispatchTime(t)}
        setCampainFrequency={(f:string) => setFrequencyType(f)}
        setCampainMessage={(m: string) => setMessage(m)}
        setCampainFrequencyDate={(fd:string) => setFrequencyDate(fd)} 
        cancel={() => cancelCreation()}
        />;
      case 6:
        return <StepSixPromotional 
        handleNext={() => setStatus(7)}
         channel={channel!}
         contacts={contacts}
         dispatchTime={dispatchTime}
         frequencyType={frequencyType}
         message={message}
         name={name}
         catalog={catalog}
         dispatchDate={dispatchDate}
         frequencyDate={frequencyDate}
         cancel={() => cancelCreation()}
         
        />;
      case 7:
      default:
        return <StepOnePromotional handleNext={() => setStatus(2)} setChannel={(c:string) => setChannel(c)} cancel={() => cancelCreation()} />;
    }
  };

  const handlePrev = () => {
    setStatus((old) => old - 1);
  };

  React.useEffect(() => {
    console.log(name);
  }, [name])

  return (
    <div>
      <nav aria-label="Progress" className="pb-6">
        <ol role="list" className="space-y-4 md:flex md:space-x-8 md:space-y-0">
          {steps.map((step) => (
            <li key={step.name} className="md:flex-1">
              {step.status === status ? (
                <span className="group flex flex-col border-l-4 border-purple-600 py-2 pl-4 hover:border-purple-800 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4">
                  <span className="text-xs font-light text-purple-500">
                    {step.name}
                  </span>
                </span>
              ) : step.status < status ? (
                <span
                  onClick={() => handlePrev()}
                  className="group flex cursor-pointer flex-col border-l-4 border-purple-600/50 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
                >
                  <span className="text-xs font-light text-purple-500/50">
                    {step.name}
                  </span>
                </span>
              ) : (
                <span className="group flex flex-col border-l-4 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4">
                  <span className="text-xs font-light text-gray-500">
                    {step.name}
                  </span>
                </span>
              )}
            </li>
          ))}
        </ol>
      </nav>
      {onboarding()}
      <ToastContainer
       position="top-right"
       autoClose={5000}
       hideProgressBar={false} // Other desired configurations
       newestOnTop={false}
       closeOnClick
       rtl={false}
       pauseOnFocusLoss
       draggable
       pauseOnHover
       theme="light"
     />
    </div>
  );
}
