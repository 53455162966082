import classNames from "classnames";
import {
  EllipsisVerticalIcon,
  EyeIcon,
  MegaphoneIcon,
  RocketLaunchIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { MenuList, UploadFile } from "../../../components";
import React, { ChangeEvent } from "react";
import { isEmpty } from "lodash";

const quoteTravel = [
  {
    date: "10/09/2017",
    client: "NAN Joyce",
    depart: "Cameroun / Douala",
    destination: "Belgique / Bruxelle",
    dateDepart: "20/09/2017",
    duree: "18 jours",
    motif: "Affaire",
    status: "Non Traité",
  },
  {
    date: "12/09/2017",
    client: "BARLOK HOLMS",
    depart: "Cameroun / Yaoundé",
    destination: "Chine / Pékin",
    dateDepart: "14/09/2017",
    duree: "7 jours",
    motif: "Affaire",
    status: "Traité",
  },
];

const quoteAuto = [
  {
    date: "15/06/2017",
    client: "LT 8596 CM",
    depart: "Berline",
    destination: "Valdo KOMLAMETA",
    dateDepart: "Toyota Camry III, diesel 2l	",
    duree: "Flotte",
    status: "Traité",
  },
  {
    date: "30/06/2017",
    client: "KM 8596 CM",
    depart: "CCross Over Moyen",
    destination: "Valdo TIKTAKARA",
    dateDepart: "Opel VT K 2L",
    duree: "Individuel",
    status: "Non Traité",
  },
  {
    date: "08/08/2017",
    client: "KM 8596 CM",
    depart: "Coupé cabriolé",
    destination: "James BAJOU KENEDY",
    dateDepart: "Mercedes Benz MLD III, diesel 5l",
    duree: "Individuel",
    status: "Non Traité",
  },
];

const tabs = [
  { name: "Assurance Voyage", href: "#", current: 0 },
  { name: "Assurance Automobile", href: "#", current: 1 },
  { name: "Assurance Automobile List Act", href: "#", current: 2 },
  { name: "Assurance Santé", href: "#", current: 3 },
];
export default function QuoteRequests() {
  const [tab, setTab] = React.useState<number>(0);
  const [error, setError] = React.useState<string>("");
  const [success, setSuccess] = React.useState<string>("");
  const [uploadFileDialog, setUploadFileDialog] = React.useState<boolean>(
    false
  );
  const alertErrorMessage = () =>
    !isEmpty(error) ? (
      <div className="rounded-md bg-red-50 p-4 mb-2">
        <div className="flex">
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">{error}</h3>
          </div>
        </div>
      </div>
    ) : (
      <></>
    );

  const alertSuccessMessage = () =>
    !isEmpty(success) ? (
      <div className="rounded-md bg-green-50 p-4 mb-2">
        <div className="flex">
          <div className="ml-3">
            <h3 className="text-sm font-medium text-green-800">{success}</h3>
          </div>
        </div>
      </div>
    ) : (
      <></>
    );

  return (
    <>
      <div className="max-w-7xl mx-auto ">
        <div className="flex flex-col divide-y ">
          <div className=" px-4 py-4 gap-x-4  ">
            <div className="">
              <div>
                <div className="sm:hidden">
                  <label htmlFor="tabs" className="sr-only">
                    Select a tab
                  </label>
                  {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                  <select
                    id="tabs"
                    name="tabs"
                    className="block w-full rounded-md border-gray-300 focus:border-blue-500 focus:ring-blue-500"
                    defaultValue={tabs.find((tab) => tab.current)!.name}
                  >
                    {tabs.map((tab) => (
                      <option key={tab.name}>{tab.name}</option>
                    ))}
                  </select>
                </div>
                <div className="hidden sm:block">
                  <div className="border-b border-gray-200">
                    <nav className="-mb-px flex" aria-label="Tabs">
                      {tabs.map((item) => (
                        <span
                          key={item.name}
                          onClick={() => setTab(item.current)}
                          className={classNames(
                            item.current === tab
                              ? "border-blue-500 text-blue-600"
                              : "border-transparent cursor-pointer text-gray-500 hover:border-gray-300 hover:text-gray-700",
                            "w-1/4 border-b-2 py-4 px-1 text-center text-sm font-medium"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </span>
                      ))}
                    </nav>
                  </div>
                </div>
              </div>
              <div className="pt-8 ">
                <div className="flex items-center gap-x-2 pb-4">
                  <a className="text-white bg-blue-600 hover:bg-blue-500 px-4 py-2 rounded-lg text-sm cursor-pointer">
                    Nouvelle Cotation
                  </a>
                </div>
                {alertErrorMessage()}
                {alertSuccessMessage()}
                <div className="flex flex-col divide-y border ">
                  <div className="flex items-center justify-between px-5 py-2">
                    <input
                      type="text"
                      className="border rounded-md h-10 px-2 w-96 font-light text-gray-600 placeholder:text-sm placeholder:text-gray-400 placeholder:font-light focus:outline-blue-600"
                      placeholder="Rechercher une cotation"
                    />
                    <div className="flex items-center gap-x-2">
                      <a className="text-white bg-green-600 hover:bg-green-500 px-4 py-2 rounded-lg text-sm cursor-pointer">
                        Télécharger en Exel
                      </a>
                      <a className="text-white bg-red-600 hover:bg-red-500 px-4 py-2 rounded-lg text-sm cursor-pointer">
                        Télécharger en PDF
                      </a>
                    </div>
                  </div>
                  <div className="p-4">
                    <div className="relative ">
                      {tab === 0 ? (
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
                          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                            <tr>
                              <th scope="col" className="px-6 py-3">
                                Date Recep. dem.
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Client
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Départ
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Destination
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Date départ
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Durée
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Motif
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Status
                              </th>
                              <th scope="col" className="px-6 py-3">
                                <span className="">Actions</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {quoteTravel.map((item, i) => {
                              return (
                                <tr
                                  key={item.date}
                                  className={classNames(
                                    {
                                      "border-b": i + 1 !== quoteTravel.length,
                                    },
                                    "bg-white  text-xs hover:bg-gray-50 "
                                  )}
                                >
                                  <th
                                    scope="row"
                                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                                  >
                                    {item.date}
                                  </th>
                                  <td className="px-6 py-4">{item.client}</td>
                                  <td className="px-6 py-4">{item.depart}</td>
                                  <td className="px-6 py-4">
                                    {item.destination}
                                  </td>
                                  <td className="px-6 py-4">
                                    {item.dateDepart}
                                  </td>
                                  <td className="px-6 py-4">{item.duree}</td>
                                  <td className="px-6 py-4">{item.motif}</td>
                                  <td className="px-6 py-4">
                                    {" "}
                                    <span
                                      className={classNames(
                                        {
                                          "bg-green-100 text-green-600":
                                            item.status === "Traité",
                                        },
                                        {
                                          "bg-red-100 text-red-600":
                                            item.status === "Non Traité",
                                        },
                                        " px-4 py-1 font-semibold rounded-md text-xs"
                                      )}
                                    >
                                      {item.status}
                                    </span>
                                  </td>
                                  <td className="px-6 py-4 text-right">
                                    <MenuList
                                      items={[
                                        {
                                          icon: RocketLaunchIcon,
                                          name: "Relancer",
                                        },
                                        {
                                          icon: MegaphoneIcon,
                                          name: "Traité",
                                          action: () => {
                                            setError("");
                                            setSuccess("");
                                            setUploadFileDialog(true);
                                          },
                                        },
                                        {
                                          icon: EyeIcon,
                                          name: "Détails",
                                        },
                                      ]}
                                      icon={
                                        <EllipsisVerticalIcon className="h-6 w-6 text-gray-700" />
                                      }
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      ) : (
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
                          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                            <tr>
                              <th scope="col" className="px-6 py-3">
                                Date Recep. dem.
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Véhicule
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Type Véh.
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Propriétaire
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Desc Véh
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Type Contrat
                              </th>

                              <th scope="col" className="px-6 py-3">
                                Status
                              </th>
                              <th scope="col" className="px-6 py-3">
                                <span className="">Actions</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {quoteAuto.map((item, i) => {
                              return (
                                <tr
                                  key={item.date}
                                  className={classNames(
                                    {
                                      "border-b": i + 1 !== quoteAuto.length,
                                    },
                                    "bg-white  text-xs hover:bg-gray-50 "
                                  )}
                                >
                                  <th
                                    scope="row"
                                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                                  >
                                    {item.date}
                                  </th>
                                  <td className="px-6 py-4">{item.client}</td>
                                  <td className="px-6 py-4">{item.depart}</td>
                                  <td className="px-6 py-4">
                                    {item.destination}
                                  </td>
                                  <td className="px-6 py-4">
                                    {item.dateDepart}
                                  </td>
                                  <td className="px-6 py-4">{item.duree}</td>
                                  <td className="px-2 py-4">
                                    {" "}
                                    <span
                                      className={classNames(
                                        {
                                          "bg-green-100 text-green-600":
                                            item.status === "Traité",
                                        },
                                        {
                                          "bg-red-100 text-red-600":
                                            item.status === "Non Traité",
                                        },
                                        " px-4 py-1 font-semibold rounded-md text-xs"
                                      )}
                                    >
                                      {item.status}
                                    </span>
                                  </td>
                                  <td className="px-6 py-4 text-right">
                                    <MenuList
                                      items={[
                                        {
                                          icon: RocketLaunchIcon,
                                          name: "Relancer",
                                        },
                                        {
                                          icon: MegaphoneIcon,
                                          name: "Traité",
                                          action: () => {
                                            setError("");
                                            setSuccess("");
                                            setUploadFileDialog(true);
                                          },
                                        },
                                        {
                                          icon: EyeIcon,
                                          name: "Détails",
                                        },
                                      ]}
                                      icon={
                                        <EllipsisVerticalIcon className="h-6 w-6 text-gray-700" />
                                      }
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <UploadFile
        dispatch={(type, message) => {
          if (type === "error") {
            setError(message);
          } else {
            setSuccess(message);
          }
          setUploadFileDialog(false);
        }}
        open={uploadFileDialog}
        setOpen={() => setUploadFileDialog(false)}
      />
    </>
  );
}
