import classNames from "classnames";
import React from "react";
import { Menu, Transition } from "@headlessui/react";
import { Fragment, useEffect, useRef, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import AddContactDialog from "./contact/add-contact-dialog";
import {
  BellAlertIcon,
  EllipsisVerticalIcon,
  EyeIcon,
  ForwardIcon,
  MegaphoneIcon,
  PauseCircleIcon,
  PencilIcon,
  PlayIcon,
  StopCircleIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { CAMPAGNE_NEW } from "../../../core/constants/layout";
import { useCampagnes } from "../../../hooks/use-campagnes";
import { Campagne, PromotionalCampagne } from "../../../models/campagne-model";
import { displayFrequencyType, extractTime, getDaysBetweenDates, getFormattedDate, getFormattedDateFr, sortWeekDays, toastConfig } from "../../../utils/utils";
import { executePromotionalCampain, fetchAllCampagnes, fetchAllPromotionalCampagnes } from "../../../services/campagnes-service";
import { Spinner } from "../../../components";
import { PageLoader } from "../../../components/page-loader";
import PlaceholderLoading from 'react-placeholder-loading'
import DataTable, { TableColumn } from "react-data-table-component";
import { WhatsappIcon } from "../../../components/icons";
import { Facebook, Instagram } from "react-content-loader";
import { toast, ToastContainer } from "react-toastify";
import DeletePromotionalCampain from "./promotional-campain/delete-promotional-campain";
import ViewPromotionalCampain from "./promotional-campain/view-promotional-campain";

export default function FollowCampagne() {
  const tabs = [
    { name: "Campagnes Promotionnels", href: "#", current: 1 },
    { name: "Campagnes Notificationels", href: "#", current: 0 },
    { name: "Campagnes Evenementiels", href: "#", current: 2 },
  ];
  const navigate = useNavigate();
  const [campagnes, setCampagnes] = React.useState<Array<Campagne>>([]);
  const [promotional_campagnes, setPromotionalCampagnes] = React.useState<Array<PromotionalCampagne>>([]);
  const [statusTab, setStatusTab] = React.useState<number>(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [tab, setTab] = React.useState<number>(1);
  const [updateOpen, setUpdateOpen] = React.useState(false);
  const [viewOpen, setViewOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [reload, setReload] = React.useState(false);
  const [selectedCampain, setSelectedCampain] = React.useState<PromotionalCampagne>();
  const secondTabs = [
    {
      name: "Tous ("+ (tab == 0 ? campagnes.length : promotional_campagnes.length) +")",
      href: "#",
      current: true,
      state: 0,
    },
    // {
    //   name: "Brouillons (1)",
    //   href: "#",
    //   current: false,
    //   state: 1,
    // },
  ];

  const columns: TableColumn<PromotionalCampagne>[] = [
    {
      name: 'NOM',
      selector: (row: PromotionalCampagne) => row.id,
      cell(row) {
          return (
          <div className="flex flex-col gap-y-1 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
              <span className="text-purple-600">
                {row.name}
              </span>
              <span className="text-xs font-light text-capitalize mt-1">
                Créé le { getFormattedDate(row.createdAt,true,true) }
              </span>
            </div>
          );
      },
    },
    {
      name: 'CANAL',
      selector: (row: PromotionalCampagne) => row.channel,
      cell(row) {
        return (
          <>
            {row.channel == "WHATSAPP"?(
              <img
              src={"/assets/svg/social media/whatsapp-color-svgrepo-com.svg"}
              className="w-5 h-5"
              alt=""
            />
            ):(<></>)}
            {row.channel == "FACEBOOK"?(
              <img
              src={"/assets/svg/social media/facebook-color-svgrepo-com.svg"}
              className="w-5 h-5"
              alt=""
            />
            ):(<></>)}
            {row.channel == "INSTAGRAM"?(
              <img
              src={"/assets/svg/social media/instagram-color-svgrepo-com.svg"}
              className="w-5 h-5"
              alt=""
            />
            ):(<></>)}
            
          </>
        );
    },
    },
    {
      name: 'TYPE DE FREQUENCE',
      selector: (row: PromotionalCampagne) => row.frequencyType,
      cell(row) {
          return (
            <div className=" px-3 py-4 text-sm text-gray-500">
              {displayFrequencyType(row.frequencyType)}
            </div>
          );
      },
    },
    {
      name: 'STATUT',
      selector: (row: PromotionalCampagne) => row.status,
      cell(row) {
        if (row.frequencyType == "ONE_TIME" && row.dispatchDate != "undefined") {
          if (typeof row.dispatchDate !== "undefined" && getDaysBetweenDates(new Date().toDateString(), row.dispatchDate) < 0) {
            return (
              <div className=" px-3 py-4 text-sm text-gray-500">
                <span 
                  className={"inline-flex items-center rounded-md bg-blue-200 px-2 py-1 text-xs font-medium text-red-800 ring-1 ring-inset ring-red-600/20"}>
                  Envoye
                </span>
              </div>
            );
          } else {
            return (
              <div className=" px-3 py-4 text-sm text-gray-500">
                <span 
                className={"inline-flex items-center rounded-md bg-"+(row.status == 0?"yellow":"green")+"-200 px-2 py-1 text-xs font-medium text-"+(row.status == 0?"yellow":"green")+"-800 ring-1 ring-inset ring-"+(row.status == 0?"yellow":"green")+"-600/20"}>
                {row.status == 0?"inactif":"actif"}
                </span>
              </div>
            );
          }
        } else {
          return (
            <div className=" px-3 py-4 text-sm text-gray-500">
              <span 
              className={"inline-flex items-center rounded-md bg-"+(row.status == 0?"yellow":"green")+"-200 px-2 py-1 text-xs font-medium text-"+(row.status == 0?"yellow":"green")+"-800 ring-1 ring-inset ring-"+(row.status == 0?"yellow":"green")+"-600/20"}>
              {row.status == 0?"inactif":"actif"}
              </span>
            </div>
          );

        }
      },
    },
    {
      name: 'DATE PREVUE/FREQUENCE',
      selector: (row: PromotionalCampagne) => row.id,
      cell(row) {
          return (
            <div className=" px-3 py-4 text-sm text-gray-500">
              {displayFrequencyDate(row)}
            </div>
          );
      },
    },
    {
      name: 'CONTACTS',
      selector: (row: PromotionalCampagne) => row.id,
      cell(row) {
          return (
            <div className=" px-3 py-4 text-sm text-gray-500">
              <span className="inline-flex items-center rounded-md bg-purple-50 px-2 py-1 text-xs font-medium text-purple-700 ring-1 ring-inset ring-purple-700/10">{row.contacts.length} dest. </span>
            </div>
          );
      },
    },
    {
      name: 'MEDIAS',
      selector: (row: PromotionalCampagne) => row.id,
      cell(row) {
          return (
            <div className=" px-3 py-4 text-sm text-gray-500">
              <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
                {row?.catalog?.length} Medias 
              </span>
            </div>
          );
      },
    },
    
    {
      name: 'ACTIONS',
      selector: (row: PromotionalCampagne) => row.id,
      cell: (row: PromotionalCampagne) => {
        return (
          <Menu
            as="div"
            className="relative inline-block text-left"
          >
            <div>
              <Menu.Button className="inline-flex w-full justify-center  px-4 py-2 text-sm font-medium text-purple-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75">
                <EllipsisVerticalIcon className="h-6 w-6" />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-50 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                <div className="px-1 py-1 ">
                  <div className="px-1 py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => {
                            setSelectedCampain(row)
                            setViewOpen(true);
                          }}
                          className={`${
                            active
                              ? "bg-purple-500 text-white"
                              : "text-gray-900"
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        >
                          {active ? (
                            <EyeIcon
                              className="mr-2 h-5 w-5"
                              aria-hidden="true"
                            />
                          ) : (
                            <EyeIcon
                              className="mr-2 h-5 w-5"
                              aria-hidden="true"
                            />
                          )}
                          Ouvrir
                        </button>
                      )}
                    </Menu.Item>
                    {/* <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${
                            active
                              ? "bg-purple-500 text-white"
                              : "text-gray-900"
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        >
                          {active ? (
                            <PencilIcon
                              className="mr-2 h-5 w-5"
                              aria-hidden="true"
                            />
                          ) : (
                            <PencilIcon
                              className="mr-2 h-5 w-5"
                              aria-hidden="true"
                            />
                          )}
                          Modifier
                        </button>
                      )}
                    </Menu.Item> */}
                  </div>

                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => {
                          if (row.status == 1) {
                            execCampain(row.id, "stop")
                          } else {
                            execCampain(row.id, "activate")
                          }
                        }}
                        className={`${
                          active
                            ? "bg-purple-500 text-white"
                            : "text-gray-900"
                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                      >
                        {row.status == 1 ? (
                          <>
                          <PauseCircleIcon
                            className="mr-2 h-5 w-5"
                            aria-hidden="true"
                          />
                          </>
                        ) : (
                          <PlayIcon
                            className="mr-2 h-5 w-5"
                            aria-hidden="true"
                          />
                        )}
                        {row.status == 0?"Recommencer":"Arreter"}
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                      onClick={() => {
                        setSelectedCampain(row)
                        setDeleteOpen(true)
                      }}
                        className={`${
                          active
                            ? "bg-purple-500 text-white"
                            : "text-gray-900"
                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                      >
                        {active ? (
                          <TrashIcon
                            className="mr-2 h-5 w-5"
                            aria-hidden="true"
                          />
                        ) : (
                          <TrashIcon
                            className="mr-2 h-5 w-5"
                            aria-hidden="true"
                          />
                        )}
                        Supprimer
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        );
      }
    },
    
    
  ];
  

  const columnsNotif: TableColumn<Campagne>[] = [
    {
      name: 'NOM',
      selector: (row: Campagne) => row.id,
      cell(row) {
          return (
          <div className="flex flex-col gap-y-1 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
              <span className="text-purple-600">
                {row.name}
              </span>
              <span className="text-xs font-light text-capitalize mt-1">
                Créé le { getFormattedDate(row.createdAt,true,true) }
              </span>
            </div>
          );
      },
    },
    {
      name: 'NUMERO',
      selector: (row: Campagne) => row.sourcePhone,
      cell(row) {
          return (
            <div className=" px-3 py-4 text-sm text-gray-500">
              {row.sourcePhone}
            </div>
          );
      },
    },
    {
      name: 'STATUT',
      selector: (row: Campagne) => row.status,
      cell(row) {
          return (
            <div className=" px-3 py-4 text-sm text-gray-500">
              <span 
              className={"inline-flex items-center rounded-md bg-"+(row.status == "PENDING"?"yellow":"green")+"-50 px-2 py-1 text-xs font-medium text-"+(row.status == "PENDING"?"yellow":"green")+"-800 ring-1 ring-inset ring-"+(row.status == "PENDING"?"yellow":"green")+"-600/20"}>
              {row.status == "PENDING"?"en attente":"execute"}
              </span>
            </div>
          );
      },
    },
    {
      name: 'DATE PREVUE/FREQUENCE',
      selector: (row: Campagne) => row.id,
      cell(row) {
          return (
            <div className=" px-3 py-4 text-sm text-gray-500">
               Dans {getDaysBetweenDates(new Date().toDateString(), row.dispatchDate)} jours<br />
                <span className="text-xs font-light">
                  {getFormattedDate(row.dispatchDate,true,false)} a {getFormattedDate(row.dispatchTime,false,true)}
                </span>
            </div>
          );
      },
    },
    {
      name: 'AUTEUR',
      selector: (row: Campagne) => row.id,
      cell(row) {
          return (
            <div className="rounded-full bg-purple-300 p-2">
              <span className="uppercase  text-purple-600 ">
                {/* {person.name.slice(0, 2)} */}
                ME
              </span>
            </div>
          );
      },
    },
    {
      name: 'CONTACTS',
      selector: (row: Campagne) => row.id,
      cell(row) {
          return (
            <div className=" px-3 py-4 text-sm text-gray-500">
              <span className="inline-flex items-center rounded-md bg-purple-50 px-2 py-1 text-xs font-medium text-purple-700 ring-1 ring-inset ring-purple-700/10">{row.contacts.length} dest. </span>
            </div>
          );
      },
    },
    {
      name: 'ACTIONS',
      selector: (row: Campagne) => row.id,
      cell: (row: Campagne) => {
        return (
          <Menu
            as="div"
            className="relative inline-block text-left"
          >
            <div>
              <Menu.Button className="inline-flex w-full justify-center  px-4 py-2 text-sm font-medium text-purple-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75">
                <EllipsisVerticalIcon className="h-6 w-6" />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-50 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                <div className="px-1 py-1 ">
                  <div className="px-1 py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${
                            active
                              ? "bg-purple-500 text-white"
                              : "text-gray-900"
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        >
                          {active ? (
                            <EyeIcon
                              className="mr-2 h-5 w-5"
                              aria-hidden="true"
                            />
                          ) : (
                            <EyeIcon
                              className="mr-2 h-5 w-5"
                              aria-hidden="true"
                            />
                          )}
                          Ouvrir
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${
                            active
                              ? "bg-purple-500 text-white"
                              : "text-gray-900"
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        >
                          {active ? (
                            <PencilIcon
                              className="mr-2 h-5 w-5"
                              aria-hidden="true"
                            />
                          ) : (
                            <PencilIcon
                              className="mr-2 h-5 w-5"
                              aria-hidden="true"
                            />
                          )}
                          Modifier
                        </button>
                      )}
                    </Menu.Item>
                  </div>

                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={`${
                          active
                            ? "bg-purple-500 text-white"
                            : "text-gray-900"
                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                      >
                        {active ? (
                          <PlayIcon
                            className="mr-2 h-5 w-5"
                            aria-hidden="true"
                          />
                        ) : (
                          <PlayIcon
                            className="mr-2 h-5 w-5"
                            aria-hidden="true"
                          />
                        )}
                        Commencer
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={`${
                          active
                            ? "bg-purple-500 text-white"
                            : "text-gray-900"
                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                      >
                        {active ? (
                          <TrashIcon
                            className="mr-2 h-5 w-5"
                            aria-hidden="true"
                          />
                        ) : (
                          <TrashIcon
                            className="mr-2 h-5 w-5"
                            aria-hidden="true"
                          />
                        )}
                        Supprimer
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        );
      }
    },
    
    
  ];
  

  const handleNavigate = () => {
    navigate(`${CAMPAGNE_NEW}`);
    window.location.reload();
  };

  const fetchCampaigns = async () => {
    try {
      setIsLoading(true);
      const data = await fetchAllCampagnes();
      setCampagnes(data);
    } catch (error) {
      console.log(error);
      toast.error(JSON.stringify(error), toastConfig);
    } finally {
      setIsLoading(false);
    }
  };

  async function fetchPromotionalCampains () 
  {
    try {
      setIsLoading(true);
      const data = await fetchAllPromotionalCampagnes();
      setPromotionalCampagnes(data);
    } catch (error) {
      console.log(error);
      toast.error(JSON.stringify(error), toastConfig);
    } finally {
      setIsLoading(false);
    }
  }
  async function execCampain (id: number, endpoint: string) 
  {
    try {
      await executePromotionalCampain(id, endpoint).then((res: boolean) => {
        if (res) {
          toast.success("Operation Effectue avec success", toastConfig)
          setReload(true);
        } else {
          toast.error("Erreur Technique", toastConfig)
        }
      }).catch(() => {
        toast.error("Erreur Technique", toastConfig)
      }).finally(() => {
      });
    } catch (error) {
      toast.error(JSON.stringify(error), toastConfig);
    }
  }
  React.useEffect(() => {
    if (tab == 0) {
      fetchCampaigns();
    } else {
      fetchPromotionalCampains()
    }
  }, [tab, reload]);

  function displayFrequencyDate(campain: PromotionalCampagne) {
    switch (campain.frequencyType) {
      case "ONE_TIME":
        if (typeof campain.dispatchDate != "undefined") {
          let days = getDaysBetweenDates(new Date().toDateString(), campain!.dispatchDate);
          return (
            <>
              {days > 0 ? `Dans ${days} jours`:""}
              {days === 0 ? `Aujourdhui`:""}
              {days < 0 ? "-":""}
              <br />
              <span className="text-xs font-light">
                {getFormattedDate(campain?.dispatchDate,true,false)} a {extractTime(campain.dispatchTime)}
              </span>
            </>
          );
        }
        break;
      case "DAILY":
        return (
          <>
            Chaque Jour <br />
            <span className="text-xs font-light">
              a {extractTime(campain.dispatchTime)}
            </span>
          </>
        );
        break;
      case "MONTHLY":
        return (
          <>
            Chaque Mois le {campain?.frequencyDate} <br />
            <span className="text-xs font-light">
              a {campain?.dispatchTime}
            </span>
          </>
        );
        break;
      case "YEARLY":
        return "Annuel";
        break;
      case "WEEKLY":
        if (typeof campain.frequencyDate != "undefined") {
          return (
            <>
              Chaque {sortWeekDays(campain?.frequencyDate)} <br />
              <span className="text-xs font-light">
                a {extractTime(campain.dispatchTime)}
              </span>
            </>
          );
        }
        break;
      
      default:
        break;
    }
  }
  

  return (
    <>
      <div className=" max-w-6xl mx-auto ">
        <div>
          <div className="sm:hidden">
            <label htmlFor="tabs" className="sr-only">
              Select a tab
            </label>
            {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
            <select
              id="tabs"
              name="tabs"
              className="block w-full rounded-md border-gray-300 focus:border-blue-500 focus:ring-blue-500"
              defaultValue={tabs.find((tab) => tab.current)!.name}
            >
              {tabs.map((tab) => (
                <option key={tab.name}>{tab.name}</option>
              ))}
            </select>
          </div>
          <div className="hidden sm:block">
            <div className="border-b border-gray-200">
              <nav className="-mb-px flex" aria-label="Tabs">
                {tabs.map((item) => (
                  <span
                    key={item.name}
                    onClick={() => setTab(item.current)}
                    className={classNames(
                      item.current === tab
                        ? "border-blue-500 text-blue-600"
                        : "border-transparent cursor-pointer text-gray-500 hover:border-gray-300 hover:text-gray-700",
                      "w-1/4 border-b-2 py-4 px-1 text-center text-sm font-medium"
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    {item.name}
                  </span>
                ))}
              </nav>
            </div>
          </div>
        </div>
        <div className="flex flex-col divide-y ">
          <div className=" px-4 py-4 gap-x-4  ">
            {tab == 0? 
            <div className="">
              <div className="flex items-center gap-2">
                <BellAlertIcon className="h-6 w-6" />
                <h1>Campagnes Notificationnels</h1>
              </div>
              <div className="mt-8 ">
                <div className="flex flex-col divide-y border ">
                  <div className="flex items-center justify-between px-5 py-2">
                    <input
                      type="text"
                      className="border rounded-md h-10 px-2 w-96 font-light text-gray-600 placeholder:text-sm placeholder:text-gray-400 placeholder:font-light focus:outline-purple-600"
                      placeholder="Rechercher une campagne"
                    />
                    <div className="flex items-center gap-x-2">
                      <a
                        onClick={() => handleNavigate()}
                        className="text-white bg-purple-600 hover:bg-purple-500 px-4 py-2 rounded-lg text-sm cursor-pointer"
                      >
                        Créer une campagne
                      </a>
                    </div>
                  </div>
                  <div className=" ">
                    <div className="flex justify-start">
                      <nav
                        className="-mb-px flex space-x-8 px-5"
                        aria-label="Tabs"
                      >
                        {secondTabs.map((tab) => (
                          <div
                            key={tab.name}
                            onClick={() => setStatusTab(tab.state)}
                            className={classNames(
                              statusTab === tab.state
                                ? "border-purple-500 text-purple-600"
                                : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                              "group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium cursor-pointer"
                            )}
                            aria-current={tab.current ? "page" : undefined}
                          >
                            <span>{tab.name}</span>
                          </div>
                        ))}
                      </nav>
                    </div>
                    {statusTab === 0 ? (
                      <>
                        <div className="px-4 sm:px-6 lg:px-8 h-full">
                          <div className="mt-8 flow-root">
                            <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8 ">
                              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                <DataTable
                                    columns={columnsNotif}
                                    data={campagnes}
                                    pagination
                                    progressPending={isLoading}
                                    progressComponent={
                                      <div className="my-2">
                                        <Spinner size="sm" />
                                      </div>
                                    }
                                  />
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="flex flex-col justify-center items-center py-8 gap-y-6">
                        <img
                          src="/assets/images/campagnes/not_found.png"
                          alt=""
                          className="h-40 w-40"
                        />
                        <h1 className="text-2xl font-semibold text-purple-600">
                          Aucun campagne trouvé
                        </h1>
                        <h5 className="text-sm font-normal text-gray-500">
                          Vous pouvez essayer avec une autre recherche ou
                          modifier les filtres de recherche
                        </h5>
                        <div className="flex items-center gap-x-2">
                          <button className="text-white bg-purple-600 hover:bg-purple-500 px-4 py-2 rounded-lg text-sm">
                            Créer une campagne
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            : 
            //
            <div className="">
              <div className="flex items-center gap-2">
                <MegaphoneIcon className="h-6 w-6" />
                <h1>Campagnes Promotionnels</h1>
              </div>
              <div className="mt-8 ">
                <div className="flex flex-col divide-y border ">
                  <div className="flex items-center justify-between px-5 py-2">
                    <input
                      type="text"
                      className="border rounded-md h-10 px-2 w-96 font-light text-gray-600 placeholder:text-sm placeholder:text-gray-400 placeholder:font-light focus:outline-purple-600"
                      placeholder="Rechercher une campagne"
                    />
                    <div className="flex items-center gap-x-2">
                      <a
                        onClick={() => handleNavigate()}
                        className="text-white bg-purple-600 hover:bg-purple-500 px-4 py-2 rounded-lg text-sm cursor-pointer"
                      >
                        Créer une campagne
                      </a>
                    </div>
                  </div>
                  <div className=" ">
                    <div className="flex justify-start">
                      <nav
                        className="-mb-px flex space-x-8 px-5"
                        aria-label="Tabs"
                      >
                        {secondTabs.map((tab) => (
                          <div
                            key={tab.name}
                            onClick={() => setStatusTab(tab.state)}
                            className={classNames(
                              statusTab === tab.state
                                ? "border-purple-500 text-purple-600"
                                : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                              "group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium cursor-pointer"
                            )}
                            aria-current={tab.current ? "page" : undefined}
                          >
                            <span>{tab.name}</span>
                          </div>
                        ))}
                      </nav>
                    </div>
                    {statusTab === 0 ? (
                      <>
                        <div className="px-4 sm:px-6 lg:px-8 h-full">
                          <div className="mt-8 flow-root">
                            <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8 ">
                              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                <DataTable
                                    columns={columns}
                                    data={promotional_campagnes}
                                    pagination
                                    progressPending={isLoading}
                                    progressComponent={
                                      <div className="my-2">
                                        <Spinner size="sm" />
                                      </div>
                                    }
                                  />
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="flex flex-col justify-center items-center py-8 gap-y-6">
                        <img
                          src="/assets/images/campagnes/not_found.png"
                          alt=""
                          className="h-40 w-40"
                        />
                        <h1 className="text-2xl font-semibold text-purple-600">
                          Aucun campagne trouvé
                        </h1>
                        <h5 className="text-sm font-normal text-gray-500">
                          Vous pouvez essayer avec une autre recherche ou
                          modifier les filtres de recherche
                        </h5>
                        <div className="flex items-center gap-x-2">
                          <button className="text-white bg-purple-600 hover:bg-purple-500 px-4 py-2 rounded-lg text-sm">
                            Créer une campagne
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            }
          </div>
        </div>
      </div>
      <ViewPromotionalCampain open={viewOpen} setOpen={() => setViewOpen(!viewOpen)} setReload={(r: boolean) => setReload(r)} campain={selectedCampain!} />
      <DeletePromotionalCampain open={deleteOpen} setOpen={() => setDeleteOpen(!deleteOpen)} setReload={(r: boolean) => setReload(r)} campain={selectedCampain!} />
      <ToastContainer
       position="top-right"
       autoClose={5000}
       hideProgressBar={false} // Other desired configurations
       newestOnTop={false}
       closeOnClick
       rtl={false}
       pauseOnFocusLoss
       draggable
       pauseOnHover
       theme="light"
     />
    </>
  );
}
