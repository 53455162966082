import React from "react";
import { CallIcon, VerticalDotIcon, VideoIcon } from "../components/icons";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/24/outline";
import { Document } from "../components";
import classNames from "classnames";
import { WhatsappContact } from "../models/contact-model";
import { NotificationTemplate } from "../models/notification-template-model";
import MobilePreview from "../components/mobile-preview";
import { isArray } from "lodash";
// import { getContactAvatar } from "../services/chat-service";

const itemsTab = [
  {
    id: 0,
    name: "Commercials",
  },
  {
    id: 1,
    name: "Reclammations",
  },
  {
    id: 2,
    name: "Satisfactions",
  },
];

interface AsideInfoProps {
  contact?: WhatsappContact;
  avatar?: string;
  template?: NotificationTemplate
  showPreview?: boolean
  templateContent?: any
}



export default function AsideInfo({
  contact,
  avatar,
  template,
  showPreview,
  templateContent
}: AsideInfoProps) {
  const [tab, setTab] = React.useState<number>(0);
  const [componentAvatar, setComponentAvatar] = React.useState<string>();
  const [displayText, setDisplayText] = React.useState<string>("");
  const [variablesArray, setVariablesArray] = React.useState([]);
  // const [avatarChecked, setAvatarChecked] = React.useState<Boolean>(false);
  React.useEffect(() => {
    if (typeof showPreview == "undefined") {
      if (typeof avatar == "undefined") {
        setComponentAvatar("/assets/images/img-1.jpg")
      } else {
        setComponentAvatar(avatar)
      }
    } else {
      if (typeof templateContent != "undefined") {
        var temp: any = variablesArray;
        temp["variable_"+templateContent?.variable] = templateContent?.value;
        let txt = "";
        if (typeof template != "undefined") {
          txt = template.message
          for (let index = 1; index <= template!.numberOfVariables; index++) {
            if (typeof temp["variable_" + index] != "undefined") {
              txt = txt.replaceAll(`{{${index}}}`, temp["variable_" + index]);
            }
          }
          setDisplayText(txt);
          setVariablesArray(temp);
        }
      }

    }
  },[avatar,templateContent, template])
  return (
    <aside className="fixed inset-y-0 right-0 hidden w-96 border-l border-gray-200 mt-12 xl:block ">
      {!showPreview? (
        <>
          <div className="flex justify-between items-center px-4 sm:px-6 lg:px-4 py-6">
            <div className="flex items-center gap-x-2">
              <h3 className="font-semibold text-lg">Directory</h3>
            </div>
            <div className="flex flex-row items-center gap-2">
              <div className="bg-green-100 flex items-center px-2 py-1 rounded-md gap-x-2">
                <CallIcon className="text-green-600 h-4 w-4" />
              </div>
              <div className="bg-green-100 flex items-center px-2 py-1 rounded-md gap-x-2">
                <VideoIcon className="text-green-600 h-4 w-4" />
              </div>
            </div>
          </div>
          <hr />
          <div className="h-full w-full grid grid-rows-3 divide-y">
            <div className="h-full col-span-2">
              <div className="max-w-md mx-auto bg-white rounded-xl overflow-hidden md:max-w-md h-full">
                <div className="md:flex h-full">
                  <div className="w-full p-4 py-10">
                    <div className="flex justify-center">
                      <div className="relative">
                        {componentAvatar != "" && typeof componentAvatar != "undefined"?(
                          <>
                            <img
                              src={componentAvatar}
                              className="rounded-full"
                              alt=""
                              width="40"
                            />
                            <span className="absolute border-white border-4 h-5 w-5 top-6 left-8 bg-green-500 rounded-full"></span>
                          </>
                          ):(
                            <>
                            <img
                              src="/assets/images/img-1.jpg"
                              className="rounded-full"
                              alt=""
                              width="40"
                            />
                            </>
                          )
                        }
                      </div>
                    </div>

                    <div className="flex flex-col text-center mt-3 mb-4">
                      <span className="text-base font-normal">{contact?.name}</span>
                      <span className="text-xs text-gray-400">
                        {contact?.pushname}
                      </span>
                    </div>

                    <p className="px-16 text-center text-xs text-gray-800">
                      {contact?.about}
                    </p>

                    <div className="flex items-center justify-center text-center  mt-3 mb-4 gap-x-2">
                      <span className="text-sm font-normal">Join:</span>
                      <span className="text-xs text-gray-400">
                        Friday, 3 May 2024
                      </span>
                    </div>

                    <div className="px-10 mt-3 text-center flex flex-wrap gap-2">
                      <span className="bg-gray-100 text-xs p-1 px-3 rounded cursor-pointer hover:shadow hover:bg-gray-200">
                        Cameroonian
                      </span>
                      <span className="bg-gray-100 text-xs p-1 px-3 rounded cursor-pointer hover:shadow hover:bg-gray-200">
                        {contact?.number}
                      </span>
                      <span className="bg-gray-100 text-xs p-1 px-3 rounded cursor-pointer hover:shadow hover:bg-gray-200">
                        CNI: 0007667
                      </span>
                      <span className="bg-gray-100 text-xs p-1 px-3 rounded cursor-pointer hover:shadow hover:bg-gray-200">
                        M
                      </span>

                      <span className="bg-gray-100 text-xs p-1 px-3 rounded cursor-pointer hover:shadow hover:bg-gray-200">
                        Frontend dev
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-4 col-span-1">
              <div className="flex items-center gap-x-2">
                {itemsTab.map((item, i) => {
                  return (
                    <div
                      onClick={() => setTab(item.id)}
                      key={i}
                      className={classNames(
                        {
                          "bg-green-200 text-green-600": tab === item.id,
                        },
                        "flex items-center p-2 font-semibold cursor-pointer hover:bg-green-200 hover:text-green-600 rounded-lg"
                      )}
                    >
                      <h3 className="font-medium text-sm ">{item.name} </h3>
                    </div>
                  );
                })}
              </div>

              <div className="p-2">
                <div className="w-full">
                  {tab === 0 ? (
                    <div className="mx-auto w-full max-w-md rounded-2xl p-2">
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex w-full justify-between rounded-lg bg-green-100 px-4 py-2 text-left text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring focus-visible:ring-green-500/75">
                              <span>See All Invoices</span>
                              <ChevronUpIcon
                                className={`${
                                  open ? "rotate-180 transform" : ""
                                } h-5 w-5 text-green-500`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500 flex flex-col gap-2">
                              <Document />
                              <Document />
                              <Document />
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                      <Disclosure as="div" className="mt-2">
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex w-full justify-between rounded-lg bg-green-100 px-4 py-2 text-left text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring focus-visible:ring-green-500/75">
                              <span>See All Contracts</span>
                              <ChevronUpIcon
                                className={`${
                                  open ? "rotate-180 transform" : ""
                                } h-5 w-5 text-green-500`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500">
                              <Document />
                              <Document />
                              <Document />
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>
                  ) : tab === 1 ? (
                    <div className="mx-auto w-full max-w-md rounded-2xl p-2">
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex w-full justify-between rounded-lg bg-green-100 px-4 py-2 text-left text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring focus-visible:ring-green-500/75">
                              <span>See All Invoices</span>
                              <ChevronUpIcon
                                className={`${
                                  open ? "rotate-180 transform" : ""
                                } h-5 w-5 text-green-500`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500 flex flex-col gap-2">
                              <Document />
                              <Document />
                              <Document />
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                      <Disclosure as="div" className="mt-2">
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex w-full justify-between rounded-lg bg-green-100 px-4 py-2 text-left text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring focus-visible:ring-green-500/75">
                              <span>See All Contracts</span>
                              <ChevronUpIcon
                                className={`${
                                  open ? "rotate-180 transform" : ""
                                } h-5 w-5 text-green-500`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500">
                              <Document />
                              <Document />
                              <Document />
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                      <Disclosure as="div" className="mt-2">
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex w-full justify-between rounded-lg bg-green-100 px-4 py-2 text-left text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring focus-visible:ring-green-500/75">
                              <span>See All Contracts</span>
                              <ChevronUpIcon
                                className={`${
                                  open ? "rotate-180 transform" : ""
                                } h-5 w-5 text-green-500`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500">
                              <Document />
                              <Document />
                              <Document />
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>
                  ) : (
                    <div className="mx-auto w-full max-w-md rounded-2xl p-2">
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex w-full justify-between rounded-lg bg-green-100 px-4 py-2 text-left text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring focus-visible:ring-green-500/75">
                              <span>See All Invoices</span>
                              <ChevronUpIcon
                                className={`${
                                  open ? "rotate-180 transform" : ""
                                } h-5 w-5 text-green-500`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500 flex flex-col gap-2">
                              <Document />
                              <Document />
                              <Document />
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                      <Disclosure as="div" className="mt-2">
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex w-full justify-between rounded-lg bg-green-100 px-4 py-2 text-left text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring focus-visible:ring-green-500/75">
                              <span>See All Contracts</span>
                              <ChevronUpIcon
                                className={`${
                                  open ? "rotate-180 transform" : ""
                                } h-5 w-5 text-green-500`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500">
                              <Document />
                              <Document />
                              <Document />
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                      <Disclosure as="div" className="mt-2">
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex w-full justify-between rounded-lg bg-green-100 px-4 py-2 text-left text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring focus-visible:ring-green-500/75">
                              <span>See All Contracts</span>
                              <ChevronUpIcon
                                className={`${
                                  open ? "rotate-180 transform" : ""
                                } h-5 w-5 text-green-500`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500">
                              <Document />
                              <Document />
                              <Document />
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      ):(
        <div className="flex align-center justify-center h-full content-baseline pt-5">
          {typeof template != "undefined" ? (
            <MobilePreview text={displayText} />
          ):(<></>)}
        </div>
      )}
      
    </aside>
  );
}
