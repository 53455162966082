import { QUOTE_REQUESTS } from "../../core/constants/layout";
import { useLayoutContext } from "../../core/context/layout-context";
import Chart from "../dashboard/components/chart";
import QuoteRequests from "./components/quote-requests";

export default function CrmClientPage() {
  const { selectChildren } = useLayoutContext();

  const ChildrenCrmClient = () => {
    switch (selectChildren) {
      case QUOTE_REQUESTS:
        return <QuoteRequests />;
      default:
        return <QuoteRequests />;
    }
  };
  return (
    <div className="xl:px-4 pt-14 h-full bg-white overflow-auto">
      {ChildrenCrmClient()}
    </div>
  );
}
