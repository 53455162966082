import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Importe les styles par défaut de ReactQuill

interface EditorProps {
  setText: (phone: string) => void;
  loading: boolean;
}
const Editor = ({
  setText,
  loading
}: EditorProps) => {
  const [content, setContent] = useState("");
  React.useEffect(() => {
    if (!loading) {
      setContent("");
    }
  },[loading])
  return (
    <ReactQuill
      theme="snow"
      value={content}
      onChange={(value) => {
        setContent(value);
        setText(value);
      }}
      className="w-full"
      readOnly={loading}
      
    />
  );
};

export default Editor;
