import {
  EyeIcon,
  PencilSquareIcon,
  RectangleGroupIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import React, { Fragment } from "react";
import {
  CAMPAGNE_NEW,
  CAMPAGNE_NEW_TEMPLATE,
} from "../../../core/constants/layout";
import { useNavigate } from "react-router-dom";
import { NotificationTemplate } from "../../../models/notification-template-model";
import { getTemplates } from "../../../services/chat-service";
import { toast } from "react-toastify";
import { getFormattedDate, toastConfig } from "../../../utils/utils";
import DataTable, { TableColumn } from "react-data-table-component";
import { Spinner } from "../../../components";

const templates = [
  {
    name: "message_diffusion",
    createdAt: "05-May-2024 02:04",
    id: "443875588990650",
    category: "UTILITY",
    status: "pending",
    language: "fr",
  },
  {
    name: "message_diffusion",
    createdAt: "05-May-2024 02:04",
    id: "443875588990650",
    category: "MARKETING",
    status: "pending",
    language: "fr",
  },
  {
    name: "message_diffusion",
    createdAt: "05-May-2024 02:04",
    id: "443875588990650",
    category: "AUTHENTICATION",
    status: "rejected",
    language: "fr",
  },
  {
    name: "message_diffusion",
    createdAt: "05-May-2024 02:04",
    id: "443875588990650",
    category: "UTILITY",
    status: "approved",
    language: "fr",
  },
];

const status = [
  { id: 1, name: "Tous" },
  { id: 2, name: "Pending" },
  { id: 3, name: "Rejected" },
  { id: 4, name: "Approved" },
];

export default function TemplateCampagne() {
  const navigate = useNavigate();

  const [selected, setSelected] = React.useState(status[0]);
  const [templates, setTemplates] = React.useState<Array<NotificationTemplate>>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [templatesFetched, setTemplatesFetched] = React.useState<boolean>(false);

  const columns: TableColumn<NotificationTemplate>[] = [
    {
      name: 'NOM',
      selector: (row: NotificationTemplate) => row.id,
      cell(row) {
          return (
          <div className="flex flex-col gap-y-1 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
              <span className="text-purple-600">
                {row.name}
              </span>
              <span className="text-xs font-light text-capitalize mt-1">
                Créé le { getFormattedDate(row.createdAt,true,true) }
              </span>
            </div>
          );
      },
    },
    {
      name: 'IDENTIFIANT WHATSAPP',
      selector: (row: NotificationTemplate) => row.id,
      cell(row) {
          return (
            <div className=" px-3 py-4 text-sm text-gray-500">
              {row.whatsappId == null || row.whatsappId == ""? "-" : row.whatsappId}
            </div>
          );
      },
    },
    {
      name: 'CATEGORIE',
      selector: (row: NotificationTemplate) => row.id,
      cell(row) {
          return (
            <div className=" px-3 py-4 text-sm text-gray-500">
              {row.category}
            </div>
          );
      },
    },
    {
      name: 'STATUT',
      selector: (row: NotificationTemplate) => row.id,
      cell(row) {
        let color = "yellow";
        let val = "en attente";
        switch (row.whatsappValidated) {
          case 1:
            color = "green";
            val = "approve";
            break;
          case -1:
            color = "red";
            val = "rejete";
            break;
          
          default:
            break;
        }
        return (
          <div className=" px-3 py-4 text-sm text-gray-500">
            <span 
            className={"inline-flex items-center rounded-md bg-"+color+"-200 px-2 py-1 text-xs font-medium text-"+color+"-800 ring-1 ring-inset ring-"+color+"-600/20"}>
            {val}
            </span>
          </div>
        );
      },
    },  
    {
      name: 'LANGUE',
      selector: (row: NotificationTemplate) => row.id,
      cell(row) {
          return (
            <div className=" px-3 py-4 text-sm text-gray-500">
              <span className="inline-flex items-center rounded-md bg-purple-50 px-2 py-1 text-xs font-medium text-purple-700 ring-1 ring-inset ring-purple-700/10">{row.language == "fr"?"FRANCAIS":"ENGLISH"} </span>
            </div>
          );
      },
    },
    {
      name: 'ACTIONS',
      selector: (row: NotificationTemplate) => row.id,
      cell: (row: NotificationTemplate) => {
        return (
          <div className="flex gap-2">
            <button className="bg-blue-100 p-1 rounded-md">
              <EyeIcon
                className="h-5 w-5 text-blue-600"
                aria-hidden="true"
              />
            </button>
            <button className="bg-yellow-100 p-1 rounded-md">
              <PencilSquareIcon
                className="h-5 w-5 text-yellow-600"
                aria-hidden="true"
              />
            </button>
            <button className="bg-red-100 p-1 rounded-md">
              <TrashIcon
                className="h-5 w-5 text-red-600"
                aria-hidden="true"
              />
            </button>
          </div>
        );
      }
    },
  ];

  async function fetchTemplates() {
    setLoading(true);
    await getTemplates().then((t) => {
      setTemplates(t);
    }).catch((e) => {
      toast.error("Erreur Technique, veillez reessayer plus tard", toastConfig)
    }).finally(() => setLoading(false))
  }

  const handleNavigate = () => {
    navigate(`${CAMPAGNE_NEW_TEMPLATE}`);
    window.location.reload();
  };

  React.useEffect(() => {
    if (!templatesFetched) {
      fetchTemplates()
      setTemplatesFetched(true);
    }
  },[])
  return (
    <>
      <div className=" max-w-7xl mx-auto ">
        <div className="flex flex-col divide-y ">
          <div className=" px-4 py-4 gap-x-4  ">
            <div className="">
              <div className="flex items-center gap-2">
                <RectangleGroupIcon className="h-6 w-6" />
                <h1>Templates</h1>
              </div>
              <div className="mt-8 ">
                <div className="flex flex-col divide-y border ">
                  <div className="flex items-center justify-between px-5 py-2">
                    <div className="flex gap-4">
                      <input
                        type="text"
                        className="border rounded-md h-10 px-2 w-96 font-light text-gray-600 placeholder:text-sm placeholder:text-gray-400 placeholder:font-light focus:outline-purple-600"
                        placeholder="Rechercher un template"
                      />
                      <Listbox value={selected} onChange={setSelected}>
                        {({ open }) => (
                          <>
                            <div className="relative w-full">
                              <Listbox.Button className="relative w-45  h-10 cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-600 sm:text-sm sm:leading-6">
                                <span className="block truncate">
                                  {selected.name}
                                </span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                  <ChevronUpDownIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </span>
                              </Listbox.Button>

                              <Transition
                                show={open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                  {status.map((statu) => (
                                    <Listbox.Option
                                      key={statu.id}
                                      className={({ active }) =>
                                        classNames(
                                          active
                                            ? "bg-purple-600 text-white"
                                            : "text-gray-900",
                                          "relative cursor-default select-none py-2 pl-3 pr-9"
                                        )
                                      }
                                      value={statu}
                                    >
                                      {({ selected, active }) => (
                                        <>
                                          <span
                                            className={classNames(
                                              selected
                                                ? "font-semibold"
                                                : "font-normal",
                                              "block truncate"
                                            )}
                                          >
                                            {statu.name}
                                          </span>

                                          {selected ? (
                                            <span
                                              className={classNames(
                                                active
                                                  ? "text-white"
                                                  : "text-purple-600",
                                                "absolute inset-y-0 right-0 flex items-center pr-4"
                                              )}
                                            >
                                              <CheckIcon
                                                className="h-5 w-5"
                                                aria-hidden="true"
                                              />
                                            </span>
                                          ) : null}
                                        </>
                                      )}
                                    </Listbox.Option>
                                  ))}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </>
                        )}
                      </Listbox>
                    </div>
                    <div className="flex items-center gap-x-2">
                      <a
                        href="#"
                        className="text-white bg-purple-600 hover:bg-purple-500 px-4 py-2 rounded-lg text-sm"
                      >
                        Télècharger un template
                      </a>
                      <button
                        onClick={() => handleNavigate()}
                        className="text-white bg-purple-600 hover:bg-purple-500 px-4 py-2 rounded-lg text-sm"
                      >
                        Créer un template
                      </button>
                      <a
                        href="#"
                        className="text-white bg-purple-600 hover:bg-purple-500 px-4 py-2 rounded-lg text-sm"
                      >
                        Guide d'utilisation
                      </a>
                    </div>
                  </div>
                  <div className=" ">
                    <>
                      <div className="px-4 sm:px-6 lg:px-8 h-full">
                        <div className="mt-8 flow-root">
                          <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8 ">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <DataTable
                                columns={columns}
                                data={templates}
                                pagination
                                progressPending={loading}
                                progressComponent={
                                  <div className="my-2">
                                    <Spinner size="sm" />
                                  </div>
                                }
                              />
                              
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
