import React, { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import {
  ChatBubbleOvalLeftEllipsisIcon,
  ChatBubbleOvalLeftIcon,
  ClockIcon,
  MegaphoneIcon,
  RocketLaunchIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";

interface StepSixProps {
  handleNext: () => void;
  sourcePhone: string;
  dispatchDate: string;
  dispatchTime: string;
  deliverySpeed: number;
  contacts: number;
  sameMessages: boolean;
}
export default function StepSix({ 
  handleNext,
  contacts,
  deliverySpeed,
  dispatchDate,
  dispatchTime,
  sameMessages,
  sourcePhone 
}: StepSixProps) {

  // function createCampain() {
    
  // }
  return (
    <div className="border mt-8">
      <div className="flex flex-col divide-y">
        <div className="grid grid-cols-2 px-4 py-8 gap-x-4 ">
          <div className="flex flex-col justify-center items-center py-24 gap-y-">
            <img
              src="/assets/images/campagnes/upload_file.png"
              alt=""
              className="h-64 w-64"
            />
          </div>
          <div className="flex flex-col gap-y-8 w-full">
            <h5 className="text-lg font-semibold text-gray-600">
              Confirmer la campagne
            </h5>
            <div className="flex flex-col justify-start items-start w-full">
              <h1 className="flex items-center gap-x-2 text-base text-center font-normal text-gray-500">
                <ChatBubbleOvalLeftIcon className="h-6 w-6" />
                Numéro WhatsApp
              </h1>
              <h5 className="flex items-center text-sm text-start font-normal text-gray-400 gap-x-2 mt-1">
                <hr className="w-2 h-2 bg-green-600 rounded-full" />
                Merlin{" "}
                <span className="text-sm text-start font-light text-gray-400">
                  {sourcePhone}
                </span>
              </h5>
            </div>

            <div className="flex flex-col justify-start items-start w-full">
              <h1 className="flex items-center gap-x-2 text-base text-center font-normal text-gray-500">
                <ClockIcon className="h-6 w-6" />
                Date de livraison de la campagne
              </h1>
              <h5 className="flex items-center text-sm text-start font-normal text-gray-400 gap-x-2 mt-1">
                {dispatchDate}, a {dispatchTime} 
              </h5>
            </div>

            <div className="flex flex-col justify-start items-start w-full">
              <h1 className="flex items-center gap-x-2 text-base text-center font-normal text-gray-500">
                <RocketLaunchIcon className="h-6 w-6" />
                Vitesse de livraison des messages
              </h1>
              <h5 className="flex items-center text-sm text-start font-normal text-gray-400 gap-x-2 mt-1">
                {deliverySpeed} message par minute (achèvement estimé en une minute)
              </h5>
            </div>

            <div className="flex flex-col justify-start items-start w-full">
              <h1 className="flex items-center gap-x-2 text-base text-center font-normal text-gray-500">
                <UserGroupIcon className="h-6 w-6" /> Nombre total de
                destinataires
              </h1>
              <h5 className="flex items-center text-sm text-start font-normal text-gray-400 gap-x-2 mt-1">
                {contacts} contact(s)
              </h5>
            </div>

            <div className="flex flex-col justify-start items-start w-full">
              <h1 className="flex items-center gap-x-2 text-base text-center font-normal text-gray-500">
                <MegaphoneIcon className="h-6 w-6" />
                Mode de diffusion de la campagne
              </h1>
              <h5 className="flex items-center text-sm text-start font-normal text-gray-400 gap-x-2 mt-1">
                Envoyer {sameMessages? "le même message à tous les contacts":"Different Message Par contact"} 
              </h5>
            </div>
            <hr />
            <div className="flex items-start gap-x-2">
              <input type="checkbox" name="" id="" />
              <span className="text-xs text-start font-normal text-gray-400 gap-x-2">
                J'accepte de respecter les{" "}
                <span className="text-purple-600">conditions de WhatsApp</span>{" "}
                et je comprends les risques associés à l'envoi de spam ou de
                messages non sollicités.{" "}
                <span className="text-purple-600">Apprendre encore plus</span>
              </span>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between px-5 py-2">
          <button className="border hover:bg-white/50 px-4 py-2 rounded-sm text-sm">
            Annuler
          </button>
          <div className="flex gap-x-2">
            <button className="border hover:bg-white/50 px-4 py-2 rounded-sm text-sm">
              Enregistrer comme brouillon
            </button>
            <button className="text-white bg-purple-600 hover:bg-purple-500 px-4 py-2 rounded-sm text-sm">
              Continuer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
