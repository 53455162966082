import React, { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import {
  ChatBubbleOvalLeftEllipsisIcon,
  ChatBubbleOvalLeftIcon,
  ClockIcon,
  MegaphoneIcon,
  RocketLaunchIcon,
  UserGroupIcon,
  ArrowsRightLeftIcon,
  BoltIcon,
  CameraIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import { storePromotionalCampain } from "../../../../services/campagnes-service";
import { useNavigate, redirect  } from "react-router-dom";
import { Spinner } from "../../../../components";
import { CAMPAGNE_FOLLOW, CAMPAGNE_NEW } from "../../../../core/constants/layout";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toastConfig } from "../../../../utils/utils";

interface StepSixProps {
  handleNext: () => void;
  channel: string;
  name: string;
  frequencyType: string;
  dispatchDate?: string;
  frequencyDate?: string;
  dispatchTime: string;
  message: string;
  contacts: Array<number>;
  catalog?: Array<number>;
  cancel: () => void;
}
export default function StepSixPromotional({ 
  handleNext,
  channel,
  name,
  frequencyType,
  dispatchDate,
  frequencyDate,
  dispatchTime,
  message,
  contacts,
  catalog,
  cancel
}: StepSixProps) {

const navigate = useNavigate();

const [loading, setLoading] = React.useState(false);
const days = [
  {
    name: "DIMANCHE",
    value: "SUN",
    id: 1,
  },
  {
    name: "LUNDI",
    value: "MON",
    id: 2,
  },
  {
    name: "MARDI",
    value: "TUE",
    id: 3,
  },
  {
    name: "MERCREDI",
    value: "WED",
    id: 4,
  },
  {
    name: "JEUDI",
    value: "THU",
    id: 5,
  },
  {
    name: "VENDREDI",
    value: "FRI",
    id: 6,
  },
  {
    name: "SAMEDI",
    value: "THU",
    id: 7,
  },
  
];


  function displayFrequency() {
    switch (frequencyType) {
      case "DAILY":
        return "Chaque Jour";
        break;
      case "MONTHLY":
        return "Chaque "+ frequencyDate + " du Mois";
        break;
      case "WEEKLY":
        days.forEach(day => {
          if (day.value == frequencyDate) {
            return "Chaque "+ day.name + " de la semaine";
          }
        });
        break;
      default:
        break;
    }
  }

  async function createCampain(status:number) {

    setLoading(true);
    const body = {
      name: name,
      channel: channel,
      frequencyType: frequencyType,
      status: status,
      message: message,
      contacts: contacts,
      catalog: catalog,
      frequencyDate: frequencyDate,
      dispatchDate: dispatchDate,
      dispatchTime: dispatchTime,
    }
    await storePromotionalCampain(body).then((res) => {
      if (res) {
        toast.success("Enregistrement Effectue", toastConfig);
        navigate(`${CAMPAGNE_FOLLOW}`);
        window.location.reload();
      } else {
        toast.error("Une erreur technique s'est produite.. veillez verifier votre formulaire", toastConfig);
      }
    }).finally(() => {
      setLoading(false);
    })
  }
  return (
    <div className="border mt-8">
      <div className="flex flex-col divide-y">
        <div className="grid grid-cols-2 px-4 py-8 gap-x-4 ">
          <div className="flex flex-col justify-center items-center py-24 gap-y-">
            <img
              src="/assets/images/campagnes/upload_file.png"
              alt=""
              className="h-64 w-64"
            />
          </div>
          <div className="flex flex-col gap-y-8 w-full">
            <h5 className="text-lg font-semibold text-gray-600">
              Confirmer la Campagne: {name}
            </h5>
            <div className="flex flex-col justify-start items-start w-full">
              <h1 className="flex items-center gap-x-2 text-base text-center font-normal text-gray-500">
                <ChatBubbleOvalLeftIcon className="h-6 w-6" />
                Numero de transmission
              </h1>
              <h5 className="flex items-center text-sm text-start font-normal text-gray-400 gap-x-2 mt-1">
                <hr className="w-2 h-2 bg-green-600 rounded-full" />
                Merlin{" "}
                <span className="text-sm text-start font-light text-gray-400">
                  +237655413390
                </span>
              </h5>
            </div>

            <div className="flex flex-col justify-start items-start w-full">
              <h1 className="flex items-center gap-x-2 text-base text-center font-normal text-gray-500">
                <ClockIcon className="h-6 w-6" />
                Frequence/Date de livraison de la campagne
              </h1>
              <h5 className="flex items-center text-sm text-start font-normal text-gray-400 gap-x-2 mt-1">
                {dispatchTime} – {displayFrequency()}
              </h5>
            </div>

            <div className="flex flex-col justify-start items-start w-full">
              <h1 className="flex items-center gap-x-2 text-base text-center font-normal text-gray-500">
                <RocketLaunchIcon className="h-6 w-6" />
                Vitesse de livraison des messages
              </h1>
              <h5 className="flex items-center text-sm text-start font-normal text-gray-400 gap-x-2 mt-1">
                1 message par minute
              </h5>
            </div>

            <div className="flex flex-col justify-start items-start w-full">
              <h1 className="flex items-center gap-x-2 text-base text-center font-normal text-gray-500">
                <UserGroupIcon className="h-6 w-6" /> Nombre total de
                destinataires
              </h1>
              <h5 className="flex items-center text-sm text-start font-normal text-gray-400 gap-x-2 mt-1">
                {contacts.length} contact(s)
              </h5>
            </div>
            <div className="flex flex-col justify-start items-start w-full">
              <h1 className="flex items-center gap-x-2 text-base text-center font-normal text-gray-500">
                <CameraIcon className="h-6 w-6" /> Nombre total d'images'
              </h1>
              <h5 className="flex items-center text-sm text-start font-normal text-gray-400 gap-x-2 mt-1">
                {catalog?.length} image(s)
              </h5>
            </div>
            
            <div className="flex flex-col justify-start items-start w-full">
              <h1 className="flex items-center gap-x-2 text-base text-center font-normal text-gray-500">
                <MegaphoneIcon className="h-6 w-6" />
                Canal de diffusion de la campagne
              </h1>
              <h5 className="flex items-center text-sm text-start font-normal text-gray-400 gap-x-2 mt-1">
                {channel}
              </h5>
            </div>
            <hr />
            <div className="flex items-start gap-x-2">
              <input type="checkbox" name="" id="" checked />
              <span className="text-xs text-start font-normal text-gray-400 gap-x-2">
                J'accepte de respecter les{" "}
                <span className="text-purple-600">conditions de {channel}</span>{" "}
                et je comprends les risques associés à l'envoi de spam ou de
                messages non sollicités.{" "}
                <span className="text-purple-600">Apprendre encore plus</span>
              </span>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between px-5 py-2">
          <button className="border hover:bg-white/50 px-4 py-2 rounded-sm text-sm" onClick={cancel}>
            Annuler
          </button>
          <div className="flex gap-x-2">
            <button className={"border hover:bg-white/50 px-4 py-2 rounded-sm text-sm " + (loading ? "hidden":"")} onClick={(e) => createCampain(0)}>
              Enregistrer comme brouillon
            </button>
            <button className={"text-white bg-purple-600 hover:bg-purple-500 px-4 py-2 rounded-sm text-sm " + (loading ? "hidden":"") } onClick={(e) => createCampain(1)}>
              Enregistrer
            </button>
            <span className={!loading ? "hidden":""}>
                <Spinner size="sm" />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
