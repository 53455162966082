import { useNavigate } from "react-router-dom";
import NewCampagne from "../new-campagne";

interface StepOneProps {
  handleNext: () => void;
  setChannel: (C:string) => void;
  cancel: () => void;
}
export default function StepOnePromotional({ handleNext, setChannel, cancel }: StepOneProps) {
  
  

  const channels = [
    {
      img:"/assets/svg/social media/whatsapp-color-svgrepo-com.svg",
      name:"WHATSAPP",
      status:1,
      description:"1 campagnes disponibles, jusqu'à 500 messages par campagne.",
    },
    {
      img:"/assets/svg/social media/facebook-color-svgrepo-com.svg",
      name:"FACEBOOK",
      status:0,
      description:"1 campagnes disponibles, jusqu'à 500 messages par campagne.",
    },
    {
      img:"/assets/svg/social media/instagram-1-svgrepo-com.svg",
      name:"INSTAGRAM",
      status:0,
      description:"1 campagnes disponibles, jusqu'à 500 messages par campagne.",
    },
    
  ];
  return (
    <div className="py-2 border">
      <div className="flex flex-col gap-y-2 mb-4 px-5">
        <h5 className="text-sm font-semibold text-gray-600">
          Sélectionnez le Canal
        </h5>
        <h5 className="text-xs font-normal text-gray-500">
          Sélectionnez le canal à partir duquel vous souhaitez envoyer la
          campagne
        </h5>
      </div>
      <div className="px-5">
        {channels.map((channel) => {
          return (
            <div
                onClick={() => {
                  if (channel.status == 1) {
                    setChannel(channel.name)
                    handleNext()
                  }
                }}
                className="flex flex-row items-center gap-x-4 border py-4 px-8 cursor-pointer mt-2"
              >
                <img
                  src={channel.img}
                  className="w-16 h-16"
                  alt=""
                />
                <div className="flex flex-col items-start gap-2">
                  <h3 className="text-sm font-medium">{channel.name}</h3>
                  <span className={"px-2 py-1 bg-"+(channel.status == 1? "green":"red")+"-600 text-white text-[10px] rounded-xl"}>
                    {channel.status == 1? "Actif":"Inactif"}
                  </span>
                  <span className="text-xs font-normal text-gray-500">
                    {channel.description}
                  </span>
                </div>
            </div>
          ) 
        })}
      </div>
      <div className="flex flex-col divide-y">
        {/* <div className="flex flex-col justify-center items-center py-8 gap-y-6">
          <img
            src="/assets/images/campagnes/campaignes-1.png"
            alt=""
            className="h-40 w-40"
          />
          <h1 className="text-2xl font-semibold text-purple-600">
            Les campagnes facilitent l'envoi de messages à plusieurs contacts
          </h1>
          <h5 className="text-sm font-normal text-gray-500">
            Créez et gérez plusieurs campagnes sur un ou plusieurs numéros
            WhatsApp pour une diffusion plus flexible et ciblée.
          </h5>
        </div> */}
        <div className="flex items-center justify-start px-5 py-2">
          <button className="border hover:bg-white/50 px-4 py-2 rounded-sm text-sm" onClick={cancel}>
            Annuler
          </button>
        </div>
      </div>
    </div>
  );
}
