import { replace } from "lodash";
import { WhatsappContact } from "../models/contact-model";
import { Chat, Message } from "../models/message-model";
import { ApiRoute } from "../utils/api-route";
import { headers } from "../utils/utils";
import { NotificationTemplate } from "../models/notification-template-model";

export async function sendText(body: any): Promise<Message | {}> {
    try {
      const response = await fetch(ApiRoute.sendText , {
        method: "POST",
        body: JSON.stringify(body),
        headers: headers()
      });
  
      if (response.status != 200) {
        throw new Error(response.status.toString());
      }
  
      const data = await response.json();
      const messages: Message[] = data;
      // const messages: Message[] = data.data;
  
      console.log("data", messages);
  
      return messages;
    } catch (e) {
      console.log("Une erreur c'est produite.", e);
  
      return [];
    }
  }

export async function checkContact(phone: string): Promise<any> {
    try {
      const response = await fetch(ApiRoute.checkPhone + `/${phone}` , {
        method: "GET",
        headers: headers()
      });
  
      if (response.status != 200) {
        throw new Error(response.status.toString());
      }
  
      const data = await response.json();
      if (data?.status == "valid") {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log("Une erreur c'est produite.", e);
  
      return false;
    }
  }


export async function getContactDetails(phone: string): Promise<WhatsappContact> {
    try {
      // const response = await fetch(ApiRoute.contacts + `/contact?chatId=${phone}` , {
      const response = await fetch(ApiRoute.contacts + `/${phone}` , {
        method: "GET",
        headers: headers()
      });
  
      if (response.status != 200) {
        throw new Error(response.status.toString());
      }
  
      const data = await response.json();
      // const contact:WhatsappContact = data;
      const contact:WhatsappContact = data.data;
      return contact;
    } catch (e) {
      console.log("Une erreur c'est produite.", e);
  
      throw new Error("Une erreur c'est produite.:"+ JSON.stringify(e));
    }
  }

export async function getContatsList(): Promise<WhatsappContact[] | any> {
    try {
      const response = await fetch(ApiRoute.contacts , {
        method: "GET",
        headers: headers()
      });
  
      if (response.status != 200) {
        throw new Error(response.status.toString());
      }
  
      const data = await response.json();

      // const contact:WhatsappContact[] = data;
      const contact:WhatsappContact[] = data.data;
      return contact;
    } catch (e) {
      console.log("Une erreur c'est produite.", e);
  
      return false;
    }
  }

export async function getChatList(): Promise<Chat[] | any> {
    try {
      const response = await fetch(ApiRoute.chatList , {
        method: "GET",
        headers: headers()
      });
  
      if (response.status != 200) {
        throw new Error(response.status.toString());
      }
  
      const data = await response.json();
      // const chats:Chat[] = data;
      const chats:Chat[] = data.data;
      return chats;
    } catch (e) {
      console.log("Une erreur c'est produite.", e);
  
      return false;
    }
  }

export async function getmessages(phone: string): Promise<Message> {
    try {
      const response = await fetch(ApiRoute.getMessages + phone , {
        method: "GET",
        headers: headers()
      });
      if (response.status != 200) {
        throw new Error(response.status.toString());
      }
  
      const data = await response.json();

      // const messages:Message = data;
      const messages:Message = data.data;
      return messages;
    } catch (e) {
      console.log("Une erreur c'est produite.", e);
  
      throw new Error("Une erreur c'est produite.:"+ JSON.stringify(e));
    }
  }


export async function getContactAvatar(phone: string): Promise<any> {
    try {
      const response = await fetch(ApiRoute.contacts + `/${phone}/avatar` , {
        method: "GET",
        headers: headers()
      });
      if (response.status != 200) {
        throw new Error(response.status.toString());
      }
  
      const data = await response.json();

      // return data?.success;
      return data.data?.success;
    } catch (e) {
      console.log("Une erreur c'est produite.", e);
  
      return false;
    }
  }

 export async function getTemplates(): Promise<NotificationTemplate[]> {
    try {
      const response = await fetch(ApiRoute.templates , {
        method: "GET",
        headers: headers()
      });
      if (response.status != 200) {
        throw new Error(response.status.toString());
      }
  
      const data = await response.json();

      // return data?.success;
      return data.data;
    } catch (e) {
      throw new Error("Une erreur c'est produite.");
    }
  }

  export async function sendTemplatedMessage(body: FormData, template_id: number): Promise<boolean> {
    try {
      const response = await fetch(ApiRoute.template_chats + "/template-message/send/" + template_id , {
        method: "POST",
        // headers: headers(),
        body: body,
        redirect: "follow"
      });
      
      if (response.status != 200) {
        throw new Error(response.status.toString());
      }
  
      return true;
    } catch (e) {
      throw new Error("Une erreur c'est produite.");
    }
  }

  

 