import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useThemeContext } from "../core/context/theme-context";

interface MenuListModel {
  icon: any;
  name: string;
  separator?: boolean;
  action?: () => void;
}

interface MenuListProps {
  icon: React.ReactNode;
  items: MenuListModel[];
}

export default function MenuList({ icon, items }: MenuListProps) {
  const { color } = useThemeContext();

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex w-full justify-center py-2 text-sm font-medium text-purple-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75">
          {icon}
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-50 mt-2 w-40 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
          <div className="px-1 py-1 ">
            {items.map((item, i) => {
              return (
                <>
                <Menu.Item key={i}>
                  {({ active }) => (
                    <button
                      onClick={item.action}
                      className={`${
                        color === "green"
                          ? "hover:bg-green-500 "
                          : color === "purple"
                          ? "hover:bg-purple-500 "
                          : color === "red"
                          ? "hover:bg-red-500 "
                          : color === "blue"
                          ? "hover:bg-blue-500 "
                          : color === "brown"
                          ? "hover:bg-stone-500 "
                          : "hover:bg-gray-500  "
                      }, group flex w-full items-center group rounded-md px-2 py-2 text-sm`}
                    >
                      <item.icon
                        className="mr-2 h-5 w-5 group-hover:text-white"
                        aria-hidden="true"
                      />
                      <span className="group-hover:text-white">
                        {item.name}
                      </span>
                    </button>
                  )}
                </Menu.Item>
                {item?.separator == true? (<hr />):(<></>)}
                </>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
