import React, { FormEvent, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckCircleIcon, CloudArrowDownIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { PhoneInput } from "react-international-phone";
import 'react-international-phone/style.css';
import { storeContacts, uploadContactsFile } from "../../../../services/contacts-service";
import { Spinner } from "../../../../components";
import { Contact } from "../../../../models/contact-model";
import { toast } from "react-toastify";
import { toastConfig } from "../../../../utils/utils";

interface ImportContactProps {
  open: boolean;
  setOpen: () => void;
  setReload: (r: boolean) => void;
}

export default function ImportContactDialog({ open, setOpen, setReload }: ImportContactProps) {
  const [file, setFile] = React.useState(null)
  const [loading, setLoading] = React.useState(false)

  async function handleSubmit(e: FormEvent) {
    e.preventDefault()
    setLoading(true)
    const formData = new FormData();
    formData.append('contacts', file!);
    await uploadContactsFile(formData).then((contacts: Contact[]) => {
      if (contacts.length > 0) {
        toast.success("Contacts Importe avec success", toastConfig);
        setReload(true);
        setOpen()
      }
    }).catch((e) => {
      toast.error(e, toastConfig);
    }).finally(() => setLoading(false))
    
  }

  function handleFileChange(event: any) {
    setFile(event.target.files[0]);
  }
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500  focus:outline-none focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={setOpen}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <form action="" method="post" onSubmit={async (e) => handleSubmit(e)}>
                  <div className="sm:flex sm:items-start w-full">
                    <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900 px-4"
                      >
                        Importer des un contact
                      </Dialog.Title>
                      <hr className="my-4" />
                      <div className="flex flex-col gap-4 w-full px-4">
                        {/*  */}
                        <div className={"rounded-md border-4 border-dashed border-"+(file === null? "purple": "green")+"-500 bg-gray-50 p-4 shadow-md w-full"}>
                          <label
                            htmlFor="upload"
                            className={"flex flex-col py-10 items-center gap-2 cursor-pointer " + (file === null? "": "hidden")}
                          >
                            <CloudArrowDownIcon className="text-purple-600 h-12 w-12" />
                            <h5 className="text-sm font-semibold text-gray-600 ">
                              Glissez et déposez le fichier ou cliquez ici pour en
                              sélectionner un
                            </h5>
                            <span className="text-xs font-light text-gray-400">
                              Seuls les fichiers CSV, XLS ou XLSX sont acceptés (jusqu'à 5 Mo)
                            </span>
                          </label>
                          <label
                            htmlFor="upload"
                            className={"flex flex-col py-10 items-center gap-2 cursor-pointer " + (file !== null? "": "hidden")}
                          >
                            <CheckCircleIcon className="text-green-600 h-12 w-12" />
                            <h5 className="text-sm font-semibold text-gray-600 ">
                              Glissez et déposez le fichier ou cliquez ici pour en
                              sélectionner un
                            </h5>
                            <span className="text-xs font-light text-gray-400">
                              Seuls les fichiers CSV, XLS ou XLSX sont acceptés (jusqu'à 5 Mo)
                            </span>
                          </label>
                          
                          <input id="upload" type="file" className="hidden" onChange={(handleFileChange)} />
                        </div>
                        <span className="text-sm font-light text-gray-400">
                          cliquez <a href="/assets/template-import-contacts.xlsx" target="_blank" className="text-purple-500 fw-bolder">ici</a> téléchargez une exemple
                        </span>
                      </div>
                    </div>
                  </div>
                  <hr className="my-8" />
                  <div className={"mt-5 sm:mt-4 sm:flex sm:flex-row-reverse justify-between px-4 "}>
                    <button
                      type="submit"
                      className={"inline-flex w-full justify-center rounded-md bg-purple-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-500 sm:ml-3 sm:w-auto " + (loading? "hidden":"")}
                      disabled={loading}
                    >
                      Importer contacts
                    </button>
                    <button
                      type="button"
                      className={"mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto " + (loading? "hidden":"")}
                      onClick={setOpen}
                      disabled={loading}
                    >
                      Annuler
                    </button>
                  </div>
                  <div className={"mt-5 sm:mt-4 sm:flex sm:flex-row-reverse justify-end px-4 "}>
                    <span className={!loading? "hidden":""}>
                      <Spinner size="sm" />
                    </span>
                  </div>
                  
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
