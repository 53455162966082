import React, { FormEvent, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckCircleIcon, CloudArrowDownIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { PhoneInput } from "react-international-phone";
import 'react-international-phone/style.css';
import { toast } from "sonner";
import { Spinner } from "../../../../../components";
import { Catalog } from "../../../../../models/catalog-model";
import { updateCatalog } from "../../../../../services/campagnes-service";

interface AddContactProps {
  open: boolean;
  setOpen: () => void;
  setReload: (r: boolean) => void;
  catalog: Catalog;
}

export default function EditCatalogDialog({ open, setOpen, setReload, catalog }: AddContactProps) {
  const [url, setUrl] = React.useState(catalog?.url)
  const [name, setName] = React.useState(catalog?.name)
  const [description, setDescription] = React.useState(catalog?.description)
  const [prix, setPrix] = React.useState(catalog?.prix)
  const [file, setFile] = React.useState(null)
  const [docType, setDocType] = React.useState(catalog?.docType)
  const [loading, setLoading] = React.useState(false)
  const [catalogSet, setContactSet] = React.useState(false)

  async function handleSubmit(e: FormEvent) {
    e.preventDefault()
    setLoading(true)
    const body = new FormData();
    body.append("url",url);
    body.append("description",description!);
    body.append("name",name);
    body.append("prix",""+prix);
    body.append("docType",docType);
    if (file !== null) {
      body.append("image",file)
    }
    // const body = {
    //   url: url,
    //   description: description,
    //   name: name,
    //   prix: prix,
    //   address: address,
    // };
    await updateCatalog(body, catalog!.id).then((res: boolean) => {
      if (res) {
        toast.success("Contact Cree avec success");
        setReload(true);
        setOpen()
      } else {
        toast.error("Erreur Lors de la creation ")
      }
    }).catch((e) => {
      toast.error(e);
    }).finally(() => setLoading(false))
  }

  function handleFileChange(event: any) {
    setFile(event.target.files[0]);
  }
  React.useEffect(() => {
    if (typeof catalog !== "undefined" && typeof catalog?.name !== "undefined" && !catalogSet) {
      setUrl(catalog?.url);
      setDescription(catalog?.description!);
      setName(catalog?.name);
      setPrix(catalog?.prix);
      setDocType(catalog?.docType);
      setContactSet(true);
    }
  },[catalogSet, catalog])

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500  focus:outline-none focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={setOpen}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <form action="" method="post" onSubmit={async (e) => handleSubmit(e)}>
                  <div className="sm:flex sm:items-start w-full">
                    <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900 px-4"
                      >
                        Modifier Article/Image
                      </Dialog.Title>
                      <hr className="my-4" />
                      <div className="flex flex-col gap-4 w-full px-4">
                      <div className="grid grid-cols-2 gap-1 justify-between">
                          <div className="">
                            <label
                              htmlFor="email"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Prix
                            </label>
                            <div className="mt-2">
                              <input
                                  type="number"
                                  name="price"
                                  className="block w-full px-4 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-0 focus:outline-purple-600 focus:ring-purple-600 sm:text-sm sm:leading-6"
                                  placeholder="Nom de L'Article"
                                  defaultValue={catalog?.prix}
                                  onInput={(e) => {
                                    setPrix(parseInt(e.currentTarget.value)) 
                                  }}
                                />
                            </div>
                          </div>
                          
                          <div>
                            <label
                              htmlFor="email"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Nom/Description De L'image/Article
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                name="name"
                                className="block w-full px-4 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-0 focus:outline-purple-600 focus:ring-purple-600 sm:text-sm sm:leading-6"
                                placeholder="Nom de L'Article"
                                required
                                defaultValue={"" +catalog?.description}
                                onInput={(e) => {
                                  setDescription(e.currentTarget.value) 
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="w-full gap-x-4">
                        <div className={"rounded-md border-4 border-dashed border-"+(file === null? "purple": "green")+"-500 bg-gray-50 p-4 shadow-md w-full"}>
                          <label
                            htmlFor="upload"
                            className={"flex flex-col py-10 items-center gap-2 cursor-pointer " + (file === null? "": "hidden")}
                          >
                            <CloudArrowDownIcon className="text-purple-600 h-12 w-12" />
                            <h5 className="text-sm font-semibold text-gray-600 ">
                              Glissez et déposez le fichier ou cliquez ici pour en
                              sélectionner un
                            </h5>
                            <span className="text-xs font-light text-gray-400">
                              Seuls les fichiers CSV, XLS ou XLSX sont acceptés (jusqu'à 5 Mo)
                            </span>
                          </label>
                          <label
                            htmlFor="upload"
                            className={"flex flex-col py-10 items-center gap-2 cursor-pointer " + (file !== null? "": "hidden")}
                          >
                            <CheckCircleIcon className="text-green-600 h-12 w-12" />
                            <h5 className="text-sm font-semibold text-gray-600 ">
                              Glissez et déposez le fichier ou cliquez ici pour en
                              sélectionner un
                            </h5>
                            <span className="text-xs font-light text-gray-400">
                              Seuls les fichiers CSV, XLS ou XLSX sont acceptés (jusqu'à 5 Mo)
                            </span>
                          </label>
                          
                          <input id="upload" type="file" className="hidden" onChange={handleFileChange} />
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="my-8" />
                  <div className={"mt-5 sm:mt-4 sm:flex sm:flex-row-reverse justify-between px-4 "}>
                    <button
                      type="submit"
                      className={"inline-flex w-full justify-center rounded-md bg-purple-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-500 sm:ml-3 sm:w-auto " + (loading? "hidden":"")}
                      disabled={loading}
                    >
                      Modifier catalog
                    </button>
                    <button
                      type="button"
                      className={"mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto " + (loading? "hidden":"")}
                      onClick={setOpen}
                      disabled={loading}
                    >
                      Annuler
                    </button>
                  </div>
                  <div className={"mt-5 sm:mt-4 sm:flex sm:flex-row-reverse justify-end px-4 "}>
                    <span className={!loading? "hidden":""}>
                      <Spinner size="sm" />
                    </span>
                  </div>
                  
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
