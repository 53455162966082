import React, { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { ClockIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";

const message = [
  { name: "4 message par minute", value:4 },
  { name: "3 message par minute" , value:3},
  { name: "2 message par minute", value:2 },
  { name: "1 message par minute", value:1 },
];

interface StepFiveProps {
  handleNext: () => void;
  setDispatchDate: (date: string) => void;
  setDispatchTime: (time: string) => void;
  setDeliverySpeed: (ds: number) => void;
  dispatchDate: string;
  dispatchTime: string;
  deliverySpeed: number;
}
export default function StepFive({ 
  handleNext, 
  setDispatchDate, 
  setDispatchTime,
  setDeliverySpeed,
  dispatchDate,
  dispatchTime,
  deliverySpeed,
}: StepFiveProps) {
  return (
    <div className="border mt-8">
      <div className="flex flex-col divide-y">
        <div className="grid grid-cols-2 px-4 py-8 gap-x-4 ">
          <div className="flex flex-col justify-center items-center py-24 gap-y-">
            <img
              src="/assets/images/campagnes/campagne-2.png"
              alt=""
              className="h-64 w-56"
            />
          </div>
          <div className="py-12 flex flex-col gap-y-8 w-full">
            <div className="flex flex-col justify-start items-start w-full">
              <h1 className="text-base text-center font-normal text-gray-500">
                Date & Heure de livraison de la campagne
              </h1>
              <h5 className="text-sm text-start font-light text-gray-400  mt-1">
                Sélectionnez une date disponible dans les 2 prochains mois
              </h5>
              <div className="grid grid-cols-2 w-full">
                <div className="mr-1">
                  <input
                    type="date"
                    className="border rounded-md h-10 w-full px-2 mt-2 placeholder:text-sm font-light text-gray-600 placeholder:text-gray-600  focus:outline-purple-600"
                    placeholder="Entrez le nom de la campagne"
                    defaultValue={dispatchDate}
                    onChange={(e) => setDispatchDate(e.currentTarget.value)}
                  />
                </div>
                <div className="ml-1">
                  <input
                    type="time"
                    className="border rounded-md h-10 w-full px-2 mt-2 placeholder:text-sm font-light text-gray-600 placeholder:text-gray-600  focus:outline-purple-600"
                    placeholder="Entrez le nom de la campagne"
                    defaultValue={dispatchTime}
                    onChange={(e) => setDispatchTime(e.currentTarget.value)}
                  />
                </div>
                
              </div>
              <span className="text-xs text-start font-light text-gray-400  mt-1">
                Vous pouvez envoyer une campagne par jour, jusqu'à 10 par mois
                dans votre plan d'abonnement actuel.
              </span>
            </div>
            <div className="flex flex-col justify-start items-start w-full">
              <h1 className="text-base text-center font-normal text-gray-500">
                Vitesse de livraison des messages
              </h1>
              <h5 className="text-sm text-start font-light text-gray-400 mt-1">
                La vitesse de livraison recommandée est de 1 message par minute
                ou moins.
              </h5>
              <Listbox value={deliverySpeed} onChange={setDeliverySpeed}>
                <div className="relative mt-1 w-full">
                  <Listbox.Button className="relative w-full cursor-default focus:outline-purple-600 rounded-md bg-white py-2 pl-8 pr-10 text-left border focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                    <span className="block truncate text-sm text-gray-600">
                      {deliverySpeed} message par minute
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 left-2 flex items-center pr-2">
                      <ClockIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                      {message.map((msg, msgIdx) => (
                        <Listbox.Option
                          key={msgIdx}
                          className={({ active }) =>
                            `relative cursor-default select-none py-2 pl-10 pr-4 ${
                              active
                                ? "bg-purple-100 text-purple-900"
                                : "text-gray-900"
                            }`
                          }
                          value={msg.value}
                        >
                          {({ selected }) => (
                            <>
                              <span
                                className={`block truncate ${
                                  selected ? "font-medium" : "font-normal"
                                } text-xs text-gray-600`}
                              >
                                {msg.name}
                              </span>
                              <span
                                className={classNames(
                                  selected ? "ext-purple-600" : "",
                                  "absolute inset-y-0 left-0 flex items-center pl-3 t"
                                )}
                              >
                                <ClockIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>
              <span className="text-xs text-start font-light text-gray-400  mt-1">
                Achèvement estimé en une minute pour 1 messages.
              </span>
            </div>
            <div className="flex flex-col justify-start items-start w-full">
              <h1 className="text-base text-center font-normal text-gray-500">
                Actions automatisées (facultatif)
              </h1>
              <h5 className="text-sm text-start font-light text-gray-400 mt-1">
                Configurez les actions post-livraison : attribuer des
                étiquettes, attribuer un chat, mettre à jour les métadonnées...
              </h5>
              <input
                type="text"
                className="border rounded-md h-10 w-full px-2 mt-2 placeholder:text-sm font-light text-gray-600 placeholder:text-gray-400 placeholder:font-light focus:outline-purple-600"
                placeholder="Configurer les actions de message"
              />
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between px-5 py-2">
          <button className="border hover:bg-white/50 px-4 py-2 rounded-sm text-sm">
            Annuler
          </button>
          <button
            onClick={() => handleNext()}
            className="text-white bg-purple-600 hover:bg-purple-500 px-4 py-2 rounded-sm text-sm"
          >
            Continuer
          </button>
        </div>
      </div>
    </div>
  );
}
