import React, { ChangeEvent, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CloudArrowDownIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useMutation } from "@tanstack/react-query";
import { chatDocument } from "../core/api/api";
import { isEmpty } from "lodash";
import { Spinner } from "./spinner";

type dispatchType = "error" | "success";

interface UploadFileProps {
  open: boolean;
  setOpen: () => void;
  dispatch: (type: dispatchType, message: string) => void;
}

export default function UploadFile({
  open,
  setOpen,
  dispatch,
}: UploadFileProps) {
  const [file, setFile] = React.useState<File | null>(null);

  const {
    mutate: createDocumentMutate,
    isPending: createDocumentIsPending,
  } = useMutation({
    mutationFn: (formdata: FormData) => {
      return chatDocument(formdata);
    },
    onSuccess(data) {
      dispatch("success", data.message);
    },
    onError(err) {
      dispatch("error", err.message);
    },
  });

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0]);
    }
  };

  const handleChatDocument = () => {
    const formData = new FormData();
    formData.append("document", file!);
    formData.append("phone", "+237653980259");
    formData.append("caption", "caption");

    createDocumentMutate(formData);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500  focus:outline-none focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={setOpen}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start w-full">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900 px-4"
                    >
                      Upload un fichier
                    </Dialog.Title>
                    <hr className="my-4" />
                    <div className="flex flex-col gap-4 w-full px-4">
                      <div className="rounded-md border-4 border-dashed border-blue-500 bg-gray-50 p-4 shadow-md w-full">
                        <label
                          htmlFor="upload"
                          className="flex flex-col py-10 items-center gap-2 cursor-pointer"
                        >
                          <CloudArrowDownIcon className="text-blue-600 h-12 w-12" />
                          <h5 className="text-sm font-semibold text-center text-gray-600 ">
                            Cliquez ici pour en sélectionner un fichier.
                          </h5>
                        </label>
                        <input
                          id="upload"
                          type="file"
                          className="hidden"
                          onChange={handleFileChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="my-8" />
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse justify-between px-4">
                  {createDocumentIsPending ? (
                    <Spinner size="md" />
                  ) : (
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
                      onClick={() => handleChatDocument()}
                    >
                      Envoyer
                    </button>
                  )}
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={setOpen}
                  >
                    Fermer
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
